import bigLogo from "../../assests/icons/logo_dark.svg";
import smallLogo from "../../assests/icons/small_logo.svg";
import styles from "./Header.module.scss";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import AvatarImage from "../AvatarImage/AvatarImage";
import SearchBar from "../SearchBar/SearchBar";
import Button, { ButtonColor, ButtonVariant } from "../basic/Button/Button";
import { useContext, useEffect, useState } from "react";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { NotificationType, User, UserRole } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../utils/general/IsMobile";
import { BiDotsVertical } from "react-icons/bi";
import OptoinsDropdown from "../basic/OptionsDropdown/OptionsDropdown";
import { MdNotificationsActive } from "react-icons/md";
import NotificationsContainer from "../App/NotificationsContainer/NotificationsContainer";

const HEADER_PROFILE_IMAGE_RADIUS = 20;

export interface HeaderProps {
  toggleMenu: () => void;
}

export default function Header({toggleMenu} : HeaderProps) {

  const { t } = useTranslation();
  const user = useContext(UserLogged) as User
  const isMobile = useIsMobile();

  const { logout, updateLogin } = useContext(AccountContext) as AccoutContextType;

  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<NotificationType[]>([])

  const { apiHelper } = useContext(AccountContext) as AccoutContextType;

  const reloadNotification = async () => {
    return apiHelper.getUserNotificatoins({})
    .then(res => setNotifications(res))
    .then(() => setLoadingNotifications(false));
  }

  useEffect(() => {
      async function update() {
          await reloadNotification();
          setTimeout(update, 30000);
      }
      update();
  }, [])

  const onLogoutClick = () => {
    logout();
    updateLogin();
  }  

  const logoImage = isMobile? smallLogo : bigLogo;
  const mobileClass = isMobile? styles.mobile : undefined;

  let headerButton = (<></>);
  if (isMobile){
    headerButton = (
      <Button className={classnames(styles.headerBtn, styles.profileBtn)} variant={ButtonVariant.Contained} color={ButtonColor.Blue} onClick={toggleMenu}>
        <BiDotsVertical/>
      </Button>
    );
  } else {
    headerButton = (
      <Button className={classnames(styles.headerBtn, styles.profileBtn)} variant={ButtonVariant.Contained} link={MainNavigationKeys.Profile + `/${user.id}`}
        color={ButtonColor.Blue}>

        {`${user.firstName} ${user.lastName}`}

        <AvatarImage srcImg={user.image} className={classnames(styles.personLogo, styles.link)} radius={HEADER_PROFILE_IMAGE_RADIUS}/>  
      </Button>
    );
  }

  return (
    <div className={styles.Header}>
      <div className={styles.leftContainer}>
        <Button 
          variant={ButtonVariant.None}
          link={MainNavigationKeys.Home}
        >
          <img
            src={logoImage}
            alt=""
            className={classnames(styles.logo, mobileClass)}
          />
        </Button>
        <SearchBar className={styles.headerSearchBar}/>
      </div>

      <div className={styles.rightContainer}>
        {headerButton}

        {(user.role === UserRole.AUTHOR && !isMobile) && <Button
          className={styles.headerBtn}
          link={MainNavigationKeys.Post}
          variant={ButtonVariant.Contained}
          color={ButtonColor.Blue}
        >
          {t('header.addpost')}
        </Button>}
        <Button
          className={styles.headerBtn}
          onClick={onLogoutClick}
          color={ButtonColor.Blue}
          variant={ButtonVariant.Contained}
        >
          {t('header.logout')}
        </Button>

        <OptoinsDropdown  width={350} 
          buttonContant={<Button color={ButtonColor.Blue} variant={ButtonVariant.Contained}>
              <MdNotificationsActive/>{notifications.filter(n => n.active).length}
            </Button>}
        >
          <NotificationsContainer onEnter={reloadNotification} notifications={notifications} loading={loadingNotifications}/>
        </OptoinsDropdown>
      </div>
    </div>
  );
}
