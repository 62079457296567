import styles from './Comment.module.scss'

import { useCallback, useContext, useState } from "react";
import { Emotions, Post, User } from "../../../../data/types";
import { UserLogged } from "../../../../navigation/AppRouter";
import { AccountContext, AccoutContextType } from "../../../../utils/Account";
import AvatarImage from '../../../AvatarImage/AvatarImage';
import Link from '../../../basic/Link/Link';
import { MainNavigationKeys } from '../../../../navigation/app/MainNavigationKeys';
import { timeSince } from '../../../../utils/general/Time';
import EmotionContainer from '../../../EmotionContainer/EmotionContainer';
import { TableWithEmotionsType } from '../../../../utils/api/ApiTypes';
import TextArea from '../../../basic/TextArea/TextArea';
import Button, { ButtonVariant } from '../../../basic/Button/Button';
import { CommentsGroup } from '../CommentsGroup/CommentsGroup';
import { useTranslation } from 'react-i18next';
import OptoinsDropdown from '../../../basic/OptionsDropdown/OptionsDropdown';
import Option from '../../../basic/OptionsDropdown/Option/Option';
import loadingGif from '../../../../assests/gif/loader.gif'
import { useQueryState } from '../../../../hooks/useQueryState';
import classnames from 'classnames';

export const COMMENTS_LENGTH_SHOW_DEFAULT = 3

export enum likeStatus  {
    LIKE = 'LIKE',
    DISLIKE = 'DISLIKE',
    NONE = 'NONE', 
}

export type CommentData = {
    text: string;
    userId: string;
    username: string;
    userImage: string;
    comments?: CommentData[];
    likes?: number;
    unLikes?: number;
    likeStatus?: likeStatus;
}

export interface CommentProps {
    data: Post,
    setData?: (post: Post) => void,
    onlyComments?: boolean,
    maxVisable?: number,
    deleteComment?: () => Promise<void>
}

export default function Comment ({
    data,
    setData = () => {},
    onlyComments = false,
    maxVisable = 0,
    deleteComment
} : CommentProps) {

  const {t} = useTranslation();
  
  const [showCommentBar, setShowCommentBar] = useState(false);
  const [focusedId, _] =  useQueryState('focused', undefined);// useState(t(tabs.Posts));

  const [commentText, setCommentText] = useState('');
  const [submitCommentLoad, setSubmitCommentLoad] = useState<boolean>(false);
  const [deleteFetching, setDeleteFetching] = useState<boolean>(false);

  const { apiHelper } = useContext(AccountContext) as AccoutContextType;
  const user = useContext(UserLogged) as User;

  const reply = () => {
    if (commentText === '') return; // ensure not empty

    setSubmitCommentLoad(true);

    const content = [{type: "paragraph", children: [{"text": commentText}]}];
    apiHelper.addComment({
      parent_id: data.id, 
      parent_parent_id: data.parentId ?? "",
      content: content
    }).then((res) => {      

      const newComment: Post = {
        authorId: user.id, 
        authorName: `${user.firstName} ${user.lastName}`, 
        authorImage: user.image,
        contant: [{type: "paragraph", children: [{"text": commentText}]}], 
        parentId: data.id, 
        comments: [],
        creationTime: new Date(),
        emotions: {},
        id: res.post_id
      }

      setData({
        ...data,
        comments: [...data.comments, newComment]
      }
      )

      setCommentText("");
      setShowCommentBar(false);
    }).catch((err) => {
      // TODO : show error
      console.error("did not managed to add comment")
      console.error(err)
    })
    .finally(() => {
      setSubmitCommentLoad(false)
    });
  }

  const setComments = (newComments: (Post | string)[]) => {
    const modifiedData = data;
    modifiedData.comments = newComments;
    modifiedData.comments.sort((a, b) => {
      if (typeof b === 'string') return 1;
      if (typeof a === 'string') return -1;
      return a.creationTime > b.creationTime? 1 : -1;
    })        
    setData(modifiedData);
  }

  const deleteCommentWrapper = useCallback(() => {
    if (!deleteComment || deleteFetching) {
      return;
    }

    setDeleteFetching(true);
    deleteComment()
    .then(() => console.log('post deleted'))
    .catch(console.error)
    .finally(() => setDeleteFetching(false));
  }, [deleteComment, deleteFetching]);

  if (onlyComments){
    return (<CommentsGroup comments={data.comments} setComments={setComments} minVisable={maxVisable}/>);
  }

  const commentDisplayedTime = timeSince(data.creationTime);

  return (
    <div className={classnames(styles.commentContainer, focusedId === data.id && styles.focused)}> 
      <div className={styles.Comment}>
            <AvatarImage 
              srcImg={data.authorImage} 
              radius={17}
            />

            <div className={styles.commentContents}>
              <div className={styles.commentText}>
                <OptoinsDropdown className={styles.optoinsMenu} width={200}>
                  <Option><b>{t('report_content')}</b></Option>
                  {deleteComment && <Option onClick={deleteCommentWrapper}>{deleteFetching? <AvatarImage radius={10} srcImg={loadingGif}/> : <b>{t('delete_comment')}</b>}</Option>}
                </OptoinsDropdown>

                <span className={styles.commentName}>
                  <Link link={MainNavigationKeys.Profile + `/${data.authorId}`}>{data.authorName}</Link>
                </span>
                {data.contant[0].children[0].text.split('\n').map(function(item, i) {
                  return (
                    item?<span key={i}>{item}<br/></span>:null
                  )
                })}

                <div className={styles.likesBar}>
                    {`${commentDisplayedTime[0]} ${t(commentDisplayedTime[1])}`}
                    <EmotionContainer 
                      emotions={data.emotions} 
                      post_id={data.id} 
                      setEmotions={(emotions: Emotions) => {setData({...data, emotions: emotions})}}
                      type={TableWithEmotionsType.Posts}
                    />
                  {"|"}
                  <Link onClick={() => setShowCommentBar(!showCommentBar)}>💬</Link>
                </div>
              </div>
              
              <CommentsGroup comments={data.comments} setComments={setComments} minVisable={maxVisable}/>

              {/* <div className={styles.commentsContainer}>
                {Comments(data.comments, 100, (value) => {}, () => {})}
              </div> */}

              
            </div>
            
          </div> 
          {showCommentBar && <div className={styles.writeComment}>
            <TextArea className={styles.replyTextArea} onChange={setCommentText} value={commentText} maxHeight={100}/>
            <Button variant={ButtonVariant.Outlined} onClick={reply} isLoading={submitCommentLoad}>{t('reply_comment')}</Button>
          </div> }
    </div>
  );
}