import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import qs from "qs"

export const useQueryState = (query: string, defaultValue?: any) => {
    const params = useParams();
    const location = useLocation()
    const navigate = useNavigate();

    const getParams = () => qs.parse(location.search, {ignoreQueryPrefix: true,});

    const setParam = (newValue: string) => {
        const queryString = qs.stringify(
            { ...getParams(), [query]: newValue},
            { skipNulls: true }
        )

        navigate(`${location.pathname}?${queryString}`)
    }

    const [value, setValue] = useState(getParams()[query] ?? defaultValue);

    const onSetValue = useCallback((newValue: string) => {
        setValue(newValue);
        setParam(newValue);
    },[query, location, navigate]);

    useEffect(() => setParam(value), [])
    useEffect(() => {
        if (getParams()[query] !== value){
            setParam(value)
        }
    }, [params])

    return [value, onSetValue]
}

