import styles from "./SideBar.module.scss";
import AvatarImage from "../AvatarImage/AvatarImage";
import { PostTopic, PostTopicsIcons, User } from "../../data/types";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import Link from "../basic/Link/Link";
import classnames from "classnames";
import { useIsMobile } from "../../utils/general/IsMobile";
import { RefObject, useContext, useRef, useState } from "react";
import { UserLogged } from "../../navigation/AppRouter";
import { MdAddCircleOutline } from "react-icons/md";
import { getKeyByValue } from "../../utils/general/enums";
import Popup from "../basic/Popup/Popup";
import { FiSettings } from "react-icons/fi";
import TopicSelect from "../TopicSelect/TopicSelect";


export interface SideBarsParams {
  authors: User[],
  open: Boolean;
}

export default function SideBar({authors, open} : SideBarsParams) {

  const sidebarRef = useRef(null) as RefObject<HTMLDivElement>;
  const [topicsPopup, setTopicsPopup] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const user = useContext(UserLogged) as User

  const mobileClass = isMobile? styles.mobile : styles.notMobile;
  const openClass = open? styles.openBar : undefined;    


  return (
    <>
    {!isMobile && <div className={styles.placeHolder}></div>}
    {<div ref={sidebarRef} className={classnames(styles.SideBar, mobileClass, openClass)}>
      <div className={styles.topSectoin}>
        {authors.map((author, i) => {
          return (
            <Link className={styles.tab} link={MainNavigationKeys.Profile + `/${author.id}`} key={i}>
              <div className={styles.symbol} key={i}>
                <AvatarImage srcImg={author.image} radius={15}/>
              </div>
              <span style={{whiteSpace: 'nowrap'}}>{`${author.firstName} ${author.lastName}`}</span>
            </Link>
          );
        })}
      </div>

      <div className={styles.middleSection}>
        <h2 className={styles.tab}><div className={styles.symbol}></div>Feed</h2>
        {user.topics?.map(topic => {
          const TopicIcon = PostTopicsIcons[topic];
          return <div className={styles.tab}><div className={styles.symbol}><TopicIcon size={25} /></div>{getKeyByValue(PostTopic, topic)}</div>
        })}
        <Link onClick={() => setTopicsPopup(true)} className={styles.tab}><div className={styles.symbol}></div><MdAddCircleOutline/> Add</Link>
      </div>

      <div className={styles.buttomSection}>
        {isMobile ? <Link className={styles.tab} link={MainNavigationKeys.Profile + `/${user.id}`}>
          <div className={styles.symbol}>
            <AvatarImage srcImg={user.image} radius={15}/>
          </div>
          <span>{`${user.firstName} ${user.lastName}`}</span>
        </Link> :
        <Link className={styles.tab} link={MainNavigationKeys.EditProfile}><div className={styles.symbol}><FiSettings size={25} /></div>Profile Settings</Link>
        }
      </div>
    </div>}
    <Popup open={topicsPopup} setOpen={setTopicsPopup}>
      <TopicSelect cencel={() => setTopicsPopup(false)}/>
    </Popup>
    </>
  );
}
