import styles from "./AvatarImage.module.scss";
import personImage from "../../assests/images/person.png"
import classnames from "classnames";

export type AvatarImageProps = {
  srcImg?: string;
  alt?: string;
  radius?: number;
  className?: string;
  onClick?: () => void;
};

export default function AvatarImage({
  srcImg,
  alt = personImage,
  radius = 20,
  className,
  onClick,
}: AvatarImageProps) {
  if (!srcImg){
    srcImg = alt;
  }

  return (
    <div
      className={classnames(styles.avatarContainer, className)}
      onClick={onClick}
    >
      <img
        src={srcImg}
        alt={alt}
        style={{ width: radius * 2, height: radius * 2, minHeight: radius * 2, minWidth: radius * 2 }}
      />
    </div>
  );
}
