import { createContext, ReactNode, useState } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../strore/store";
import { ActionTypes, LoginStatus } from "../strore/SessionAuth";
import { SERVER_DOMAIN } from "./api/ApiTypes"
import { ApiHelper } from "./api/ApiHelper";

export type Session = {
 user: CognitoUser, 
 session: CognitoUserSession, 
 attributes: any
}

export type AccoutContextType = {
    login: (Username: string, Password: string) => Promise<unknown>, 
    logout: () => void,
    confirmEmail: (username: string, code: string) => Promise<unknown>
    changePassword: (oldPassword: string, newPassword: string) => void,
    forgotPassword: (username: string) => Promise<void>,
    confirmForgotPassword: (username: string, verificationCode: string, newPassword: string) => Promise<void>,
    updateLogin: () => void, // TODO : replace with fetchData(url, paramsOfFetch)
    fetchData: (input: RequestInfo, params?:any, headers?: any, body?: any, method?: string, withoutContentType?: boolean) => Promise<Response>,
    apiHelper: ApiHelper,
}

const AccountContext = createContext<AccoutContextType | null>(null);

export interface AccoutProps{
    children?: ReactNode;
}

const Account = (props: AccoutProps) => {

  const [session, setSession] = useState<Session | null>(null)

  const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)
  const dispatch = useDispatch();

  async function fetchData (input: RequestInfo, params?:any, headers?: any, body?: any, method?: string, withoutContentType?: boolean) : Promise<Response> {
    input = SERVER_DOMAIN + input;
    
    if (loginStatus !== LoginStatus.LOGGED_IN && loginStatus !== LoginStatus.NEED_TO_COMPLETE_DETAILS) {
      throw Error("you are not logged in. you can't make api calls.")
    }

    let newSession;
    if (session === null){
      newSession = await getSession()
      setSession(newSession);
    } else {
      newSession = session;
    }
    
    let headers_ : {[key: string]: string}= {
      'Authorization': "Bearer " + newSession.session.getIdToken().getJwtToken(),
    }
    if (!withoutContentType){
      headers_ = {
        ...headers_,
        'Content-Type': 'application/json'
      }
    } 

    headers_ = {...headers_, ...headers}

    let obj : RequestInit = {
      headers: new Headers(headers_)
    }

    if (body){
      obj = {...obj, body: body}
    }

    if (method){
      obj = {...obj, method: method}
    }

    let url = new URL(input);
    if (params)
      url.search = buildParams(params);

    return new Promise<Response>((resolve, reject) => { 
      fetch(url.toString(), obj).then(resolve).catch(reject);
    })
  }

  const updateLogin = () => {
    if (session) {
      dispatch({type: ActionTypes.SIGNED_IN})
    }

    dispatch({type: ActionTypes.START_FETCHING})
    getSession().then((session_got) => {
      console.log("Session: ", session_got);
      setSession(session_got);
      dispatch({type: ActionTypes.SIGNED_IN});
    }).catch((err: Error) => {
      console.log("you are not logged in!");
      setSession(null);
      dispatch({type: ActionTypes.SIGNED_OUT})
    });
  }

  const login = async (Username: string, Password: string) => {
    // dispatch({type: ActionTypes.START_FETCHING});

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
  
      const authDetails = new AuthenticationDetails({ Username, Password });
      
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          dispatch({type: ActionTypes.SIGNED_IN});
          resolve(data);
        },
        onFailure: (err) => {
          
          dispatch({type: ActionTypes.SIGNED_OUT});
          reject(err);
        },
        newPasswordRequired: (data) => {          
          dispatch({type: ActionTypes.REQUIRED_CHANGE_PASSWORD});
          resolve(data);
        },
      });
    });
  };

  const confirmEmail = async (Username: string, code: string) => {

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      user.confirmRegistration(code, true, (error, result) => {
        if (error){
          reject(error);
        }
        resolve(result);
      });
    });
  }

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      dispatch({type: ActionTypes.START_FETCHING});
      user.signOut();
      dispatch({type: ActionTypes.SIGNED_OUT});
      console.log("Signed out");
    }
  };

  const changePassword = (oldPassword: string, newPassword: string) => {
    dispatch({type: ActionTypes.START_FETCHING})
    getSession().then(({ user }) => {
      user.changePassword(oldPassword, newPassword, (err?: Error, result?: any) => {
        if (err) {
          console.error(err);
        } else {
          console.log(result);
        }
      });
    }).catch((err: Error) => {
      console.log(err);
      
      console.log("cant change password you are not logged in!");
      dispatch({type: ActionTypes.SIGNED_OUT})
    });;
  }

  const forgotPassword = (username: string) => {
    const user = new CognitoUser({ Username: username, Pool });
    return new Promise<void>((resolve, reject) => user.forgotPassword({
      onSuccess: (data) => resolve(),
      onFailure: (err) => reject(err)
    }));
  }

  const confirmForgotPassword = (username: string, verificationCode: string, newPassword: string) => {
    const user = new CognitoUser({ Username: username, Pool });
    return new Promise<void>((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
          onFailure: (err) => reject(err),
          onSuccess: () => resolve(),
      });
    });
  }

  const api_helper = new ApiHelper(fetchData);

  return (
    <AccountContext.Provider value={{
      login:login, 
      changePassword:changePassword, 
      logout:logout, 
      confirmEmail:confirmEmail,
      forgotPassword: forgotPassword,
      confirmForgotPassword: confirmForgotPassword,
      updateLogin:updateLogin,
      fetchData:fetchData,
      apiHelper: api_helper
    }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };

async function getSession() : Promise<Session> {
  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err: Error, session: any) => {
        if (err) {
          reject(err);
        } else {
          const attributes:any  = await new Promise((resolve, reject) => {
            user.getUserAttributes((err?: Error, attributes?: CognitoUserAttribute[]) => {
              if (err) {
                reject(err);
              } else {
                const results : any = {};

                if (attributes){
                  for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                  }
                }

                resolve(results);
              }
            });
          });

          resolve({ user, session, attributes });
        }
      });
    } else {
      reject('no user');
    }
  });
};

function buildParams(data: {[key: string]: any}) {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
          value.forEach(value => params.append(key, value.toString()))
      } else {
          params.append(key, value.toString())
      }
  });

  return params.toString()
}
