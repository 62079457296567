import classnames from "classnames"
import { ReactNode } from "react"
import styles from "./Option.module.scss"

export interface OptionProps {
    children?: ReactNode,
    noHover?: boolean,
    className?: string,
    onClick?: () => void
}

export default function Option({children, noHover = false, className, onClick}: OptionProps) {

    let optionClasses = [styles.option]
    if (!noHover) {
        optionClasses = [...optionClasses, styles.optionHover]
    }

    if (className) {
        optionClasses = [...optionClasses, className]
    }

    return <div className={classnames(optionClasses)} onClick={onClick}>{children}</div>
}