import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import styles from './Link.module.scss'

export type LinkProps = {
    children?: React.ReactNode;
    className?: string;
    link?: string;
    onClick?: () => void;
  };

export default function Link({
    children,
    className,
    link,
    onClick
}: LinkProps){

    const navigate = useNavigate();

    const onClickWrapper = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.button === 0){      
        event.preventDefault();
        if (onClick){
          onClick();
        } else if (link) {
          navigate(link);
        }
      }
    }

    return (
        <a 
          className={classnames(styles.linkA, className)}
          onMouseDown={onClickWrapper}
        >
          {children}
        </a>
    );
}