import { Navigate, useNavigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import authStyles from './AuthenticationForm.module.scss';
import { useCallback, useContext, useState } from "react";
import logo from "../../assests/images/LogoDark.png";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { useSelector } from "react-redux";
import { RootState } from "../../strore/store";
import { LoginStatus } from "../../strore/SessionAuth";
import loadingGif from '../../assests/gif/loader.gif'
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import Link from "../../components/basic/Link/Link";


export default function LoginScreen() {

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)


  const { login } = useContext(AccountContext) as AccoutContextType;
  const submit = useCallback(async (event: any) => {
    event.preventDefault();

    setLoading(true);
    setError("");
    login(email, password).then((data) => {
      navigate(MainNavigationKeys.Home);
    })
    .catch((err: Error) => {
      if (err.name === 'UserNotConfirmedException'){
        navigate(MainNavigationKeys.VerifyEmail + `?username=${email}`)
        return;
      }
      
      setError(err.message);
    })
    .finally(() => {
      setLoading(false)
    });
  }, [login, loading, error, email, password]);

  if (loginStatus === LoginStatus.LOGGED_IN){
    return <Navigate to={MainNavigationKeys.Home}/>
  }

  return (
    <div className={authStyles.fullPage}>
      <form className={authStyles.authForm}>
          <div className={authStyles.logo}><img src={logo} alt=""/></div>
          <div className={authStyles.container}>
          <label form="uname">
              <b>Username</b>
          </label>
          <input
              type="text"
              placeholder="Enter Username"
              name="uname"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
          />

          <label form="psw">
              <b>Password</b>
          </label>
          <input
              type="password"
              placeholder="Enter Password"
              name="psw"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
          />
          {error && <p className={authStyles.errorMessage}>{error.length < 50? error : 'Unknown Error'}</p>}
          <button
              className={authStyles.submitButton}
              type="submit"
              onClick={submit}
          >
              {loading? <AvatarImage radius={10} srcImg={loadingGif}/> : "Login"}
          </button>
          {/* <label>
              <input type="checkbox" name="remember" /> Remember me
          </label> */}
          </div>

          <div
          className={authStyles.container}
          style={{ backgroundColor: "#f1f1f1" }}
          >
          <button
              type="button"
              className={authStyles.secondaryBtn}
              onClick={() => navigate(MainNavigationKeys.Register)}
          >
              {"Sign Up"}
          </button>
          <Link className={authStyles.psw} link={MainNavigationKeys.ResetPassword}>
              Forgot password?
          </Link>
          {/* <span className={authStyles.psw}>
              Forgot password?
          </span> */}
          </div>
      </form>
    </div>
  );
}

