import { useContext, useEffect, useState } from "react";
import { UserLogged } from "../../navigation/AppRouter";
import { PostTopic, User } from "../../data/types";
import styles from './TopicSelect.module.scss';
import classnames from "classnames";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import Button, { ButtonColor } from "../basic/Button/Button";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { isSameSet } from "../../utils/general/sets";
import { getKeyByValue } from "../../utils/general/enums";

export interface TopicSelectProps {
    cencel?: () => void;
}

export default function TopicSelect({cencel}: TopicSelectProps) {

    const { apiHelper } = useContext(AccountContext) as AccoutContextType;
    const user = useContext(UserLogged) as User;
    const [topics, setTopics] = useState<PostTopic[] | undefined>(undefined)
    
    useEffect(() => {
        if (user && !topics) {
            setTopics(user.topics)
        }
    }, [user, topics])

    const updateUserTopics = () => {
        if (isSameSet(new Set(topics), new Set(user.topics))) {
            cencel && cencel()
            return;
        }

        apiHelper.updateUser({topics: topics})
        .then(res => window.location.reload())
        .catch(err => console.error(err))
        .finally(() => {});
    }

    return (<div className={styles.topicsSelectContainer}>
        {topics?.map(topic => <div className={classnames(styles.choose)} onClick={() => setTopics(topics.filter(t => t !== topic))}><AiOutlineMinusCircle/> {getKeyByValue(PostTopic, topic)}</div>)}
        <hr />
        {Object.entries(PostTopic).filter(([name, value]) => !topics || topics.indexOf(value) < 0)
        .map(([name, value]) => <div className={classnames(styles.choose)} onClick={() => setTopics([...topics ?? [], value])}><MdAddCircleOutline/> {name}</div>)}
    
        <hr />
        <div>
            <Button color={ButtonColor.Black} onClick={updateUserTopics}>Save</Button>
            <Button color={ButtonColor.Black} onClick={cencel}>Cencel</Button>
        </div>
    </div>);
} 