import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCommentDetail, BiDislike, BiLike } from "react-icons/bi";
import { Emotion, Post } from "../../data/types";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import PostViewer from "../App/PostViewer/PostViewer";
import AvatarImage from "../AvatarImage/AvatarImage";
import Button from "../basic/Button/Button";
import FullScreenView from "../basic/FullScreenView/FullScreenView";
import Link from "../basic/Link/Link";
import { ElementComponentProps } from "../HorizontalCollection/HorizontalCollection";
import styles from './PostBlock.module.scss'
import { getReadingTime } from "../../utils/posts";
import { getTimeInterval } from "../../utils/general/Time";
import { AiOutlineClockCircle } from "react-icons/ai";
import classnames from "classnames";


export function PostBlock({element, maxWidth}: ElementComponentProps) {
    const {t} = useTranslation();

    const [hover, setHover] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [fullyFull, setFullyFull] = useState<boolean>(false);

    const [post, setPost] = useState<Post>(element as Post);

    useEffect(() => setPost(element), [element]);

    return (<div className={styles.block} style={{maxWidth}}>
      {fullScreen && <FullScreenView onExit={() => setFullScreen(false)} withHeader={!fullyFull}>
        <div className={styles.noDistractionBtn}><Button onClick={() => setFullyFull(val => !val)}>{"⤢"}</Button></div>
        <PostViewer post={post} setPost={setPost} withComments={!fullyFull}/>
      </FullScreenView>}
  
      <div className={styles.expander} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} 
          onClick={() => setFullScreen(true)}>

        <Link link={`${MainNavigationKeys.Profile}/${post.authorId}`}>
          <div className={styles.writer}>
              <AvatarImage srcImg={post.authorImage} radius={20}/>{post.authorName}
          </div>
        </Link>

        <div className={styles.title}>
          {post.title? `"${post.title}"` : 'No Title'}
        </div>

        <div className={styles.statisticsPanel}>
            <div className={classnames(styles.statisticContainer, styles.floatLeft)}>
                <AiOutlineClockCircle/>{getTimeInterval(getReadingTime(post), true)}
            </div>
            
            <div className={styles.statisticContainer}><BiCommentDetail/>{post.comments.length}</div>

            <div className={styles.statisticContainer}>
                <BiLike/>{post.emotions[Emotion.LIKE]? Object.keys(post.emotions[Emotion.LIKE]).length: 0}
            </div>

            <div className={styles.statisticContainer}>
                <BiDislike/>{post.emotions[Emotion.DISLIKE]? Object.keys(post.emotions[Emotion.DISLIKE]).length: 0}
            </div>
        </div>
        {/* {hover && 
        <div className={styles.statisticsPanel}>
          <div className={styles.statisticContainer}><p>👍</p><p>{3}</p></div>
          <div className={styles.statisticContainer}><p>👎</p><p>{3}</p></div>
        </div>
        } */}
      </div>
    </div>);
  }