import isHotkey from "is-hotkey";
import { BaseEditor, Editor, Element as SlateElement, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  RenderElementProps,
  DefaultElement,
  RenderLeafProps,
  ReactEditor,
} from "slate-react";
import { EDITOR_TOOLBAR_KEYBOARD_SHORTCUT_ANALYTIC_TYPE } from "../../utils/api/ApiHelper";
import { AlignOptions, PostContentParagraph, PostContentParagraphChildren } from "../../utils/types";

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: PostContentParagraph;
    Text: PostContentParagraphChildren;
  }
}

export function getActiveStyles(editor: CustomEditor) {
  return new Set(Object.keys(Editor.marks(editor) ?? {}));
}

export function toggleStyle(editor: CustomEditor, style: string) {
  const activeStyles = getActiveStyles(editor);
  if (activeStyles.has(style)) {
    Editor.removeMark(editor, style);
  } else {
    Editor.addMark(editor, style, true);
  }
}

export function isBlockActive(editor: CustomEditor, format: string, type: 'type' | 'align' = 'type') {
  const { selection } = editor;
  if (!selection) return false;

  const mathcs = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n[type] === format,
  });

  return !mathcs.next().done;
}

export const toggleBlock = (editor: CustomEditor, format: string, type: 'type'| 'align' = 'type') => {
  const isActive = isBlockActive(editor, format, type);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
      split: true,
  });

  let newProperties: Partial<SlateElement>
  if (type === 'align'){
    newProperties = {
      align: isActive ? undefined : format as AlignOptions,
    }
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export function renderElement(props: RenderElementProps) {
  const { element, children, attributes } = props;
  
  const style = { 
    textAlign: element.align,
    direction: (element.align && element.align === 'right')?'rtl' as 'rtl' : undefined,
  }

  switch (element.type) {
    case "paragraph":
      return <p style={{...style, padding: '15px 0'}} {...attributes}>{children}</p>;
    case "h1":
      return <h1 style={{...style, padding: '20px 0'}} {...attributes}>{children}</h1>;
    case "h2":
      return <h2 style={{...style, padding: '10px 0'}} {...attributes}>{children}</h2>;
    case "h3":
      return <h3 style={{...style, padding: '8px 0'}} {...attributes}>{children}</h3>;
    case "h4":
      return <h4 style={{...style, padding: '8px 0'}} {...attributes}>{children}</h4>;
    default:
      // For the default case, we delegate to Slate's default rendering.
      return <DefaultElement {...props} />;
  }
}

export function renderLeaf({ attributes, children, leaf }: RenderLeafProps) {
  let el = <>{children}</>;

  if (leaf.bold) {
    el = <strong>{el}</strong>;
  }

  if (leaf.code) {
    el = <code>{el}</code>;
  }

  if (leaf.italic) {
    el = <em>{el}</em>;
  }

  if (leaf.underline) {
    el = <u>{el}</u>;
  }

  return <span {...attributes}>{el}</span>;
}

export const KeyBindings = {
  onKeyDown: (editor: CustomEditor, event: React.KeyboardEvent, onChange?: any) => {
    let value, callback;
    if (isHotkey("mod+b", event)) {
      callback = toggleStyle;
      value = 'bold';
    } else if (isHotkey("mod+i", event)) {
      callback = toggleStyle;
      value = 'italic';
    } else if (isHotkey("mod+u", event)) {
      callback = toggleStyle;
      value = 'underline';
    } else if (isHotkey("alt+1", event)) {
      callback = toggleBlock;
      value = 'h1';
    } else if (isHotkey("alt+2", event)) {
      callback = toggleBlock;
      value = 'h2';
    } else if (isHotkey("alt+3", event)) {
      callback = toggleBlock;
      value = 'h3';
    } else if (isHotkey("alt+`", event)) {
      callback = toggleBlock;
      value = 'paragraph';
    }

    if (callback && value) {
      callback(editor, value);
      if (onChange) onChange({type: EDITOR_TOOLBAR_KEYBOARD_SHORTCUT_ANALYTIC_TYPE, value: value})
    }
  },
};
