import { Navigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { useSelector } from 'react-redux'
import { RootState } from "../../strore/store";
import { LoginStatus } from "../../strore/SessionAuth";
import { useContext } from "react";
import { UserLogged } from "../../navigation/AppRouter";
import { User } from "../../data/types";
import FetchingScreen from "../../screens/FetchingScreen/FetchingScreen";

export interface RequireAuthProps {
    children?: React.ReactNode
}

function RequireAuth({ children }: RequireAuthProps) {

  const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)
  const user = useContext(UserLogged) as User;


  // if (loginStatus === LoginStatus.FETCHING){
  //   return <>Fetching..</>
  // }
  // console.log(LoginStatus[loginStatus])  

  if (loginStatus === LoginStatus.LOGGED_OUT){
    return <Navigate to={MainNavigationKeys.Login} />
  }

  if (loginStatus === LoginStatus.REQUIRED_CHANGE_PASSWORD){
    console.log('moving to reset password');
    
    return <Navigate to={MainNavigationKeys.ResetPassword} />
  }

  if (loginStatus === LoginStatus.NEED_TO_COMPLETE_DETAILS){
    return <Navigate to={MainNavigationKeys.CompleteDetails} />
  }

  if (!user){
    return <FetchingScreen/>
  }

  return (
    <>{children}</>
  );
}

export { RequireAuth }