import { MdSportsTennis } from "react-icons/md";
import { PostContent } from "../utils/types"
import { GiPoliceOfficerHead, GiTakeMyMoney } from 'react-icons/gi';

export const COMMENTS_NOT_FETCHED = 0

export enum Emotion {
    LIKE = 1,
    DISLIKE = -1
}
export type Emotions = { [key: number]: {[user_id: string]: number} }

export enum UserRole {
    READER = "REGULAR",
    AUTHOR = "AUTHOR"
}

export enum PostTopic {
    Economy = 'ECONOMY', 
    Politics = 'POLITICS', 
    Sport = 'SPORT'
}

export const PostTopicsIcons = {
    [PostTopic.Economy]: GiTakeMyMoney,
    [PostTopic.Politics]: GiPoliceOfficerHead,
    [PostTopic.Sport]: MdSportsTennis,
}

export type Post = {
    id: string,
    parentId: string | null,
    authorId: string,
    authorName: string,
    authorImage?: string,
    title?: string,
    contant: PostContent,
    creationTime: Date,
    emotions: Emotions,
    topic?: PostTopic,
    comments: (Post | string)[]
}

export type SavedPost ={
    id: string
    creationTime: Date,
    modificationTime: Date
    contant: PostContent,
    writerId: string
}

export type Forum = Post & {
    comments: (Forum | string)[],
    title?: string,
    owner_id: string
}

export type NotificationType = {
    creation_time: Date,
    data: any,
    id: string,
    notification_type: string
    active: boolean,
    first_name: string,
    second_name: string,
    user_image: string
}

export type User = {
    id: string,
    email: string,
    role: UserRole,
    firstName: string,
    lastName: string,
    bio: string,
    image?: string,
    coverImage?: string,
    phoneNumber?: string,
    topics?: PostTopic[],
    location?: string,
    birthday?: Date,
    creationTime: Date,
    subscryptions: {id: string, first_name: string, second_name: string}[],
    followers: {id: string, first_name: string, second_name: string, profile_image: string, user_type: string}[],
    followedBy: {id: string, first_name: string, second_name: string, profile_image: string, user_type: string}[],
    prefered_language?: string, 
    notifications: NotificationType[] | number
}
