import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import SessionReducer from './SessionAuth'

export const store = configureStore({
    reducer: {
        sessionReducer: SessionReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type ThunkAction = (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  getState: () => RootState,
  extraArgument: unknown
) => void;

export default store;