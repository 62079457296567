import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MainNavigationKeys } from "../../../navigation/app/MainNavigationKeys";
import { AccountContext, AccoutContextType } from "../../../utils/Account";
import authStyles from '../AuthenticationForm.module.scss';
import logo from "../../../assests/images/LogoDark.png";
import loadingGif from '../../../assests/gif/loader.gif'
import AvatarImage from "../../../components/AvatarImage/AvatarImage";


export default function ConfirmEmailScreen(){

    const [searchParams, setSearchParams] = useSearchParams();
    const username = searchParams.get('username');

    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate()
    const { confirmEmail } = useContext(AccountContext) as AccoutContextType;

    const verify = () => {
        if(!username) return;

        setLoading(true);
        confirmEmail(username, code).then(() => {
            navigate(MainNavigationKeys.Login);
        }).catch((err) => {
            setError(err.message)
        })
        .finally(() => setLoading(false));
    }

    if (!username) {
        return (
        <div className={authStyles.fullPage}>
            <h2>Error: no username set</h2>
        </div>
        );
    }

    return (
    <div className={authStyles.fullPage}>
        <div className={authStyles.authForm}>
            <img className={authStyles.logo} src={logo} alt=""/>
            <div className={authStyles.container}>
                <label>
                    <b>Verification Code :</b>
                    <input 
                        value={code} 
                        onChange={(event) => setCode(event.target.value)} 
                        type="text" 
                    />
                </label>
                {error && <div className={authStyles.errorMessage}>{error}</div>}
                <button className={authStyles.submitButton} onClick={() => verify()}>
                    {loading?  <AvatarImage radius={10} srcImg={loadingGif}/> : "Confirm"}
                </button>
            </div>
        </div>
    </div>
    );
}