import { useTranslation } from 'react-i18next';
import loadingGif from '../../assests/gif/loader.gif'
import styles from "./FetchingScreen.module.scss";

export interface FetchingScreenParams {
  text?: string
}

export default function FetchingScreen({text} : FetchingScreenParams) {

  const {t} = useTranslation()

  return (
    <div className={styles.fullPage}>
      <img className={styles.loadingGif} src={loadingGif} />
      {text? text : t('loading_page')}
    </div>
  );
}
