import { Descendant } from "slate";
import { Editable } from "slate-react";
import React, { Dispatch, useCallback } from "react";
import styles from "./TextEditor.module.scss";
import {
  CustomEditor,
  KeyBindings,
  renderElement,
  renderLeaf,
} from "./EditorUtils";
import classnames from "classnames";
import { analyticReportRequest } from "../../utils/api/ApiTypes";

export interface TextEditorProps {
  className?: string;
  document: Descendant[];
  editor: CustomEditor;
  setDocument: Dispatch<React.SetStateAction<Descendant[]>>;
  setMarks?: Dispatch<React.SetStateAction<Set<string>>>;
  toggleMark?: string;
  onChangeStyle?: (data: analyticReportRequest) => Promise<void>;
}

export default function TextEditor({
  className,
  document,
  editor,
  setDocument,
  setMarks,
  toggleMark,
  onChangeStyle
}: TextEditorProps) {
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
      KeyBindings.onKeyDown(editor, event, onChangeStyle);
  }, [editor]);

  return (
      <Editable
        className={classnames(className, styles.editorClass)}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        spellCheck
      />
  );
}
