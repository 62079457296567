import { useEffect, useMemo, useState } from "react";

export function useOnScreen(ref: React.MutableRefObject<Element>, rootMargin: string = '0px') {

    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { rootMargin }
        )
        
        ref.current && observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [ref.current])
  
    return isIntersecting
  }