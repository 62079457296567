import classnames from "classnames";
import styles from "./TextArea.module.scss"
import { ChangeEvent, useEffect, useRef } from 'react'

export interface TextAreaProps {
    value?: string;
    className?: string;
    placeholder?: string;
    maxHeight?: number;
    onChange?: (val: string) => void;
}

export default function TextArea (props: TextAreaProps) {
    let classes = [styles.tellemTextArea];

    if (props.className) {
        classes.push(props.className)
    }

    const ref = useRef<HTMLTextAreaElement>(null);

    const onChangeHandler = function(e: ChangeEvent<HTMLTextAreaElement>) {
        if (props.onChange) {
            props.onChange(e.target.value)
        }
    };

    useEffect(() => {
        if (ref.current != null){
            ref.current.style.height = "2.5rem";

            let hight = ref.current.scrollHeight;
            if (props.maxHeight && props.maxHeight < hight) {
              hight = props.maxHeight;
            } 
            ref.current.style.height = `${hight}px`;
        }
    }, [props.value, props.maxHeight])

    return (
        <textarea ref={ref} value={props.value} className={classnames(classes)} onChange={onChangeHandler} placeholder={props.placeholder}/>
    );
}

