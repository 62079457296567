import classnames from "classnames";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useIsMobile } from "../../../utils/general/IsMobile";
import Button, { ButtonColor, ButtonVariant } from "../Button/Button";
import styles from "./OptionsDropdown.module.scss"
import { useLocation } from "react-router-dom";

export interface OptionsDropdownProps {
    children?: (JSX.Element | undefined | false)[] | JSX.Element;
    width?: number,
    className?: string,
    buttonContant?: ReactNode 
}

export default function OptoinsDropdown({children, width, className, buttonContant} : OptionsDropdownProps) {

    const [open, setOpen] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const optionsRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const menuRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [elementStyles, setElementStyles] = useState<any>({width: width})
    const location = useLocation();


    useEffect(() => {    
      setOpen(false);    
    }, [location])  
    useClickOutside(optionsRef, () => setOpen(false));

    let dropdownClasses = [styles.dropdownContainer]

    if (className) {
        dropdownClasses = [...dropdownClasses, className]
    }

    useEffect(() => {
        if (!menuRef.current) return;

        const containerRect = optionsRef.current.getBoundingClientRect()
        const offsetLeft = Math.min(containerRect.left, window.innerWidth - menuRef.current.offsetWidth)
        const offsetTop = containerRect.bottom + 5;

        setElementStyles({...elementStyles, left: `${offsetLeft}px`, top: `${offsetTop}px`})
    }, [open])

    // const calculatePages = useCallback(() => {          
    //     if (!menuRef.current) return;

    //     const windowWidth = window.innerWidth;
    //     const currentRight = menuRef.current.offsetLeft;
    //     const w = width ?? 0;

    //     if (currentRight + w > windowWidth) {
    //         elementStyles.transform = `translateX(-${currentRight + w - windowWidth}px);`
    //     }

    // }, []);

    // useEffect(() => {
    //     calculatePages();
    //     window.addEventListener("resize", calculatePages)
    //     return () => window.removeEventListener("resize", calculatePages)
    // }, [calculatePages])

    return <div className={classnames(dropdownClasses, isMobile && styles.mobile)} ref={optionsRef}>
        {<div onClick={() => setOpen(!open)}>{buttonContant}</div> ??
        <Button className={styles.optionBtn} variant={ButtonVariant.Flat} color={ButtonColor.Gray} onClick={() => setOpen(!open)}>⋮</Button>}
        <div ref={menuRef} className={classnames(styles.optionsContainer, open && styles.open)} style={elementStyles}>
            {children}
        </div>
    </div>
}

