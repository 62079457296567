import { Session } from "../utils/Account";

export enum LoginStatus {
    FETCHING,
    LOGGED_IN,
    LOGGED_OUT,
    REQUIRED_CHANGE_PASSWORD,
    NEED_TO_COMPLETE_DETAILS,
    UNKNOWN
}

export interface SessionState {
    loginStatus: LoginStatus,
}

const initialState: SessionState = { loginStatus: LoginStatus.UNKNOWN };

export enum ActionTypes{
    START_FETCHING,
    SIGNED_IN,
    SIGNED_OUT,
    REQUIRED_CHANGE_PASSWORD,
    NEED_TO_COMPLETE_DETAILS
}

type ActionStructure = {
    type: ActionTypes,
    value?: Session
}

const SessionReducer = (state = initialState, action: ActionStructure = { type: ActionTypes.START_FETCHING}) => {
  switch (action.type) {
    case ActionTypes.START_FETCHING:
      return {
        loginStatus: LoginStatus.FETCHING
      }
    case ActionTypes.SIGNED_IN:
      return {
        loginStatus: LoginStatus.LOGGED_IN,
      };

    case ActionTypes.SIGNED_OUT:
      return {
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case ActionTypes.REQUIRED_CHANGE_PASSWORD:
      return {
        loginStatus: LoginStatus.REQUIRED_CHANGE_PASSWORD,
      };
    case ActionTypes.NEED_TO_COMPLETE_DETAILS:
      return {
        loginStatus: LoginStatus.NEED_TO_COMPLETE_DETAILS,
      };

    default:
      return state;
  }
};

export default SessionReducer;