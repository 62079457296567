import { useContext, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiLike } from 'react-icons/bi';
import { MdLocalFireDepartment, MdOutlineWatchLater } from 'react-icons/md';
import { Emotion } from '../../../data/types';
import { AccountContext, AccoutContextType } from '../../../utils/Account';
import Button, { ButtonColor, ButtonVariant } from '../../basic/Button/Button';
import Card from '../../basic/Card/Card';
import Link from '../../basic/Link/Link';
import styles from './SuggestionsContainer.module.scss';

export enum SuggestionsTabs {
    New = 'creation_time',
    Hot = 'votes',
    Add = 'add'
}

export interface SuggestionsContainerProps {
    ownerId: string
    // suggestions: any[]
}

export default function SuggestionsContainer({ownerId}: SuggestionsContainerProps){

    const [tab, setTab] = useState<SuggestionsTabs>(SuggestionsTabs.Hot);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [addSuggestionContant, setAddSuggestionContant] = useState<string>('');

    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    const refresh = () => {
        apiHelper.getSuggestions({wait_for_execution: true, owner_id: ownerId})
        .then(res => setSuggestions(res.suggestions));
    }

    useEffect(() => {
        refresh();
    }, []);

    const addSuggestion = () => {
        if (addSuggestionContant.length > 120) {
            setError('The maximum length is 120 characters.')
            return;
        }

        apiHelper.addSuggestion({owner_id: ownerId, topic: addSuggestionContant})
        .then((id) => {
            refresh();
            setTab(SuggestionsTabs.New);
            setAddSuggestionContant('');
        })
    }

    const likeSuggestion = (suggestion_id: string) => {
        return () => {
            apiHelper.likeSuggestion({suggestion_id: suggestion_id, emotion: Emotion.LIKE})
            .then(refresh)
        }
    }

    let all = [];
    if (tab === SuggestionsTabs.New){
        all = suggestions.sort((a, b) => a.creation_time > b.creation_time? -1 : 1)
    } else {
        all = suggestions.sort((a, b) => {            
            return Object.keys(a.emotions[Emotion.LIKE]).length > Object.keys(b.emotions[Emotion.LIKE]).length? -1 : 1
        })
    }
    

    return (<div className={styles.container}>
        <div className={styles.controlBar}>
            <Button isActive={tab==SuggestionsTabs.New} color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => setTab(SuggestionsTabs.New)}>Latest<MdOutlineWatchLater/></Button>
            <Button isActive={tab==SuggestionsTabs.Hot} color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => setTab(SuggestionsTabs.Hot)}>Hot<MdLocalFireDepartment/></Button>
            <Button isActive={tab==SuggestionsTabs.Add} color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => setTab(SuggestionsTabs.Add)}>Add<AiOutlinePlus/></Button>
        </div>
        <div className={styles.suggestions}>
            {tab === SuggestionsTabs.Add? 
            <div className={styles.addContainer}>
                <h4>What whould you like to see? (up to 120 characters)</h4>
                <textarea value={addSuggestionContant} onChange={event => setAddSuggestionContant(event.target.value)}/>
                {error && <div className={styles.error}>{error}</div>}
                <Button className={styles.addBtn} onClick={addSuggestion}>Add</Button>
            </div>
            :
            all.length === 0? 
                <h2>No suggestions yet</h2> :
                <>
                    {all.map(suggestion => (<Card className={styles.suggestion}>
                        <div className={styles.topicDiv}>
                            {suggestion.topic}
                            {/* <AvatarImage srcImg={suggestion.image}/> */}
                            <p className={styles.openedBy}>requested by: <Link link={`/profile/${suggestion.writer_id}`}>{suggestion.first_name} {suggestion.second_name}</Link></p>
                        </div>                    

                        {Object.entries(suggestion.emotions[Emotion.LIKE]).length}
                        <Button className={styles.likeBtn} onClick={likeSuggestion(suggestion.id)}><BiLike/></Button>
                    </Card>))}
                </>
            }
        </div>
    </div>);
}