import classnames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Button.module.scss";
import loadingGif from '../../../assests/gif/loader.gif'
import AvatarImage from "../../AvatarImage/AvatarImage";


export enum ButtonVariant {
  None = "NONE",
  Link = "LINK",
  Outlined = "OUTLINED",
  FlatOutlined = "FLAT_OUTLINED",
  Contained = "CONTAINED",
  Flat = "FLAT",
  ToggleFlat = "TOGGLE_FLAT",
}

export enum ButtonColor {
  Gray = "GRAY",
  Blue = "BLUE",
  Black = "BLACK",
  Green = "GREEN",
  Red = "RED",
}

export type ButtonProps = {
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  variant?: ButtonVariant;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  color?: ButtonColor;
  rounded?: boolean;
  link?: string;
};

export default function Button({
  onClick,
  children,
  variant = ButtonVariant.Flat,
  className,
  isActive = false,
  isDisabled = false,
  isLoading = false,
  color = ButtonColor.Gray,
  rounded = true,
  link,
  title,
}: ButtonProps) {
  
  const { t } = useTranslation()
  const navigate = useNavigate();

  let classes : string[] = [styles.tellemBtn]
  if (className) {
    classes = [className, styles.tellemBtn]
  }  

  if (variant === ButtonVariant.Flat){
    classes.push(styles.faltButtonVarient);
  } else if (variant === ButtonVariant.Contained){
    classes.push(styles.containedButtonVarient);
  } else if (variant === ButtonVariant.Link){
    classes.push(styles.linkButtonVarient);
  } else if (variant === ButtonVariant.Outlined){
    classes.push(styles.outlinedButtonVarient);
  } else if (variant === ButtonVariant.FlatOutlined){
    classes.push(styles.flatOutlinedButtonVarient);
  } else if (variant === ButtonVariant.ToggleFlat){
    classes.push(styles.toggleFlatButtonVarient);
    if (!isActive){
      classes.push(styles.toggleFlatInactiveButtonVarient);
    }
  }

  if (color === ButtonColor.Gray){
    classes.push(styles.grayButtonColor);
  } else if (color === ButtonColor.Blue){
    classes.push(styles.blueButtonColor);
  } else if (color === ButtonColor.Black){
    classes.push(styles.blackButtonColor);
  } else if (color === ButtonColor.Green){
    classes.push(styles.greenButtonColor);
  } else if (color === ButtonColor.Red){
    classes.push(styles.redButtonColor);
  }

  if (rounded){
    classes.push(styles.roundedButton);
  }

  if (isLoading) {
    children = <><AvatarImage radius={10} srcImg={loadingGif}/></>
  }

  if (isActive) classes.push(styles.active)

  const onClickWrapper = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick){
      onClick(event);
    } else if (link) {
      navigate(link);
    }
  }

  const btn = (
    <button type="button" className={classnames(classes)} onClick={onClickWrapper} title={title}>
      {children}
    </button>
  );

  return (
    link? 
      <Link className={styles.linkWrapperBtn} to={link}>{btn}</Link> 
      : btn
  );
}
