import { useEffect } from "react";

export function useOnResize(callback: () => any){

    useEffect(() => {
        callback();
        window.addEventListener("resize", callback)
        return () => window.removeEventListener("resize", callback)
    }, [callback])

}