import styles from './Toolbar.module.scss' 
import { useSlate } from "slate-react";
import {
  toggleStyle,
  getActiveStyles,
  isBlockActive,
  toggleBlock,
  CustomEditor,
} from "../../TextEditor/EditorUtils";
import { FaBold, FaItalic, FaUnderline } from "react-icons/fa";
import { FiAlignRight, FiAlignLeft, FiAlignJustify } from "react-icons/fi";
import { MdOutlineLooksOne, MdOutlineLooksTwo, MdOutlineLooks3 } from "react-icons/md";
import classnames from 'classnames';
import { analyticReportRequest } from '../../../utils/api/ApiTypes';
import { EDITOR_TOOLBAR_BUTTONS_PRESS_ANALYTIC_TYPE } from '../../../utils/api/ApiHelper';

export interface EditorToolbarProps {
  className?: string;
  onChangeStyle?: (data: analyticReportRequest) => Promise<void>
}

export default function EditorToolbar(props: EditorToolbarProps) {
  const editorMarks = useSlate();

  function toggleStyleWrap(editor: CustomEditor, style: string) {
    return (event: any) => {
      event.preventDefault();
      toggleStyle(editor, style);
      if (props.onChangeStyle) props.onChangeStyle({type: EDITOR_TOOLBAR_BUTTONS_PRESS_ANALYTIC_TYPE, value: style})
    }
  }

  function toggleBlockWrap(editor: CustomEditor, format: string, type: 'type'| 'align' = 'type') {
    return (event: any) => {
      event.preventDefault();
      toggleBlock(editor, format, type);
      if (props.onChangeStyle) props.onChangeStyle({type: EDITOR_TOOLBAR_BUTTONS_PRESS_ANALYTIC_TYPE, value: format})
    }
  }

  return (
    <div className={classnames(props.className, styles.container)}>
      <div 
        className={classnames(styles.btn, getActiveStyles(editorMarks).has("bold") && styles.active)}
        onMouseDown={toggleStyleWrap(editorMarks, "bold")}
      >
        <FaBold/>
      </div>
      <div 
        className={classnames(styles.btn, getActiveStyles(editorMarks).has("italic") && styles.active)}
        onMouseDown={toggleStyleWrap(editorMarks, "italic")}
      >
        <FaItalic/>
      </div>
      <div 
        className={classnames(styles.btn, getActiveStyles(editorMarks).has("underline") && styles.active)}
        onMouseDown={toggleStyleWrap(editorMarks, "underline")}
      >
        <FaUnderline/>
      </div>
      <div className={styles.seperator}></div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "h1") && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "h1")}
      >
        <MdOutlineLooksOne/>
      </div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "h2") && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "h2")}
      >
        <MdOutlineLooksTwo/>
      </div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "h3") && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "h3")}
      >
        <MdOutlineLooks3/>
      </div>
      <div className={styles.seperator}></div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "left", 'align') && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "left", 'align')}        
      >
        <FiAlignLeft/>
      </div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "center", 'align') && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "center", 'align')}
      >
        <FiAlignJustify/>
      </div>
      <div 
        className={classnames(styles.btn, isBlockActive(editorMarks, "right", 'align') && styles.active)}
        onMouseDown={toggleBlockWrap(editorMarks, "right", 'align')}
      >
        <FiAlignRight/>
      </div>
    </div>
  );
}
