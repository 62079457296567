import classnames from 'classnames';
import { useRef } from 'react';
import { useClickDirectOn } from '../../../hooks/useClickOutside';
import styles from './FullScreenView.module.scss'

export interface FullScreenViewProps {
    children?: React.ReactNode;
    onExit?: () => void;
    withHeader?: boolean;
}

export default function FullScreenView({children, onExit, withHeader = false} : FullScreenViewProps) {
    
    const optionsRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    useClickDirectOn(optionsRef, () => onExit && onExit());

    return (<div className={classnames(styles.fullScreenWrapper, withHeader && styles.withHeader)}>
        <div className={styles.exitBtn} onClick={onExit}>close</div>
        <div ref={optionsRef} className={styles.contentContainer}>
            {children}
        </div>
    </div>);
}