import styles from "./ProfileScreen2.module.scss";
import HubScreen from "../../components/HubScreen/HubScreen";
import Card from "../../components/basic/Card/Card";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { Forum, Post, User, UserRole } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import { useNavigate, useParams } from "react-router-dom";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import Tabs from "../../components/basic/Tabs/Tabs";
import Tab from "../../components/basic/Tabs/Tab";
import Link from "../../components/basic/Link/Link";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import loadingGif from '../../assests/gif/loader.gif'
import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import { useTranslation } from 'react-i18next';
import Input from "../../components/basic/Input/Input";
import OptoinsDropdown from "../../components/basic/OptionsDropdown/OptionsDropdown";
import Option from "../../components/basic/OptionsDropdown/Option/Option";
import FeedLoader from "../../components/basic/FeedLoader/FeedLoader";
import ProfileDetailsCard from "./ProfileDetailsCard";
import { useIsMobile } from "../../utils/general/IsMobile";
import MobileBottomTabs from "../../components/basic/MobileBottomTabs/MobileBottomTabs";
import { PostBlock } from "../../components/PostBlock/PostBlock";
import SuggestionsContainer from "../../components/App/SuggestionsContainer/SuggestionsContainer";
import { useQueryState } from "../../hooks/useQueryState";
import ForumListCard from "./ForumListCard";

enum tabs {
  Posts = "posts_tab",
  Forums = "forums_tab",
  Live = "live_tab",
  Suggestions = 'suggestions'
}

export default function ProfileScreen2() {
  
  const { id } = useParams();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] =  useQueryState('tab', t(tabs.Posts));// useState(t(tabs.Posts));


  const [forums, setForums] = useState<Forum[]>([])
  const [fetchingForums, setFetchingForums] = useState<boolean>(false);

  const [user, setUser] = useState<User>()

  const [error, setError] = useState<string>()
  const [newForumTitle, setNewForumTitle] = useState<string>('');
  const [deleteForumFetching, setDeleteForumFetching] = useState<boolean>(false);

  const { apiHelper } = useContext(AccountContext) as AccoutContextType;
  const my_user = useContext(UserLogged) as User;

  const isMobile = useIsMobile();

  const navigate = useNavigate();  

  // useEffect(() => {
  //   setActiveTab(tabs.Posts);
  // }, [t])

  useEffect(() => {
    setUser(undefined);
    if (!id){
      setError("Got to profile screen without profile name")
    } else if (my_user){
      // fetching forums
      setFetchingForums(true);
      apiHelper.getForumsAPI({owner_id: id, posts_limit: 10}).then((res) => {
        setForums(res.forums.filter(forum => typeof forum !== 'string') as Forum[])        
      }).catch(() => setError("Error in fetching posts"))
      .finally(()=> {setFetchingForums(false)});

      // fetching profile
      apiHelper.getProfile({user_id: id}).then((res) => setUser(res.user)).catch(() => setError("Error in fetching user"));
    }
  }, [my_user, apiHelper, id])
  

  const deleteForum = useCallback((post_id: string) => {
    if (deleteForumFetching) return;

    setDeleteForumFetching(true);
    apiHelper.deleteForumAPI({post_id})
    .then(() => setForums(forums.filter(f => f.id.toString() !== post_id.toString())))
    .catch(console.error)
    .finally(() => setDeleteForumFetching(false));
  }, [apiHelper, deleteForumFetching, forums]);

  async function getProfliePosts(limit: number, offset: number) {
    if (!id) return [];
    return apiHelper.getPostsAPI({writers: [id], posts_limit: limit, offset: offset}).then(res => res.posts as Post[]);
  }

  if (!id) {
    return <ErrorScreen message={"wrong URL."}/>;
  }

  if (error) {
    return <ErrorScreen message={error ?? "Unknown Error"}/>;
  }

  if (!my_user || !user) {
    return (<div className={styles.ProfileScreen}>
      <AvatarImage className={styles.loader} radius={40} srcImg={loadingGif}/>
      {/* {t('loading_profile')} */}
    </div>);
  }    

  if (user.role !== UserRole.AUTHOR || (!my_user.followers.map(sub => sub.id.toString()).includes(user.id) && id !== my_user.id)){
    return (
      <div className={styles.ProfileScreen}>
        <ProfileDetailsCard user={user}/>
        {user.role === UserRole.AUTHOR && <FeedLoader ElementComponent={PostBlock} breakingWidth={isMobile? 320 : 250} fetchPosts={getProfliePosts}/>}
      </div>
    );
  }

  if (isMobile){
    const tabs = {
      Profile: <ProfileDetailsCard user={user}/>,
      Posts: <FeedLoader ElementComponent={PostBlock} breakingWidth={320} fetchPosts={getProfliePosts}/>,
      Forums: <>
        <div className={styles.forumsCards}>
          <Card className={styles.newForumCard}>
            <span className={styles.newForumLabel}>{t('enter_forum_title_label')}:</span>
            <Input className={styles.newForumInput} value={newForumTitle} onChange={setNewForumTitle} maxLength={70}/>
            <Button className={styles.submitBtn} color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => navigate(MainNavigationKeys.NewForum + `/${id}?title=${newForumTitle}`)}>{t('submit_btn')}</Button>
          </Card>
          {fetchingForums? <AvatarImage srcImg={loadingGif}/> 
            : forums.map((item) => <ForumListCard item={item} deleteForum={deleteForum} deleteForumFetching={deleteForumFetching}/>)}
        </div>
      </>, 
      Suggestions: <SuggestionsContainer ownerId={user.id}/>,
      Live: <h2>{t('comming_soon')}</h2>
    }

    return (
      <div className={styles.ProfileScreen}>
        <MobileBottomTabs tabs={tabs}/>
      </div>
    );
  }
  
  return (
    <>
      <div className={styles.ProfileScreen}>  {/*footer={user.role === UserRole.AUTHOR? true: false}>*/}
        
        <ProfileDetailsCard user={user}/>
        
        {user.role === UserRole.AUTHOR &&
          <Tabs activeLabel={activeTab} setActiveLabel={setActiveTab as any} headersClassName={styles.tabsHeaders}>
            <Tab label={t(tabs.Posts)}>
              <FeedLoader ElementComponent={PostBlock} breakingWidth={250} fetchPosts={getProfliePosts}/>
            </Tab>
            
            <Tab label={t(tabs.Forums)}>
              <div className={styles.forumsCards}>
              <Card className={styles.newForumCard}>
                <span className={styles.newForumLabel}>{t('enter_forum_title_label')}:</span>
                <Input className={styles.newForumInput} value={newForumTitle} onChange={setNewForumTitle} maxLength={70}/>
                <Button className={styles.submitBtn} color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => navigate(MainNavigationKeys.NewForum + `/${id}?title=${newForumTitle}`)}>{t('submit_btn')}</Button>
              </Card>
              {fetchingForums? <AvatarImage srcImg={loadingGif}/> : forums.map((item) => <ForumListCard item={item} deleteForum={deleteForum} deleteForumFetching={deleteForumFetching}/>)}
              </div>
            </Tab>

            <Tab label={"Suggestions"}>
              <SuggestionsContainer ownerId={user.id}/>
            </Tab>
          </Tabs>
        }
        
      </div>
    </>
  );
}
