import HubScreen from "../../components/HubScreen/HubScreen";
import styles from "./WelcomeScreen.module.scss";
import { useContext, useState } from "react";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { User } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import { useIsMobile } from "../../utils/general/IsMobile";
import { Navigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";

export default function WelcomeScreen() {

  const [error, setError] = useState<string>()  

  const { apiHelper, updateLogin } = useContext(AccountContext) as AccoutContextType;
  const user = useContext(UserLogged) as User;  

  const isMobile = useIsMobile();

  if (error) {
    return <ErrorScreen message={error ?? "Unknown Error"}/>;
  }

  if (user && user.followers.length > 0) {
    return <Navigate to={MainNavigationKeys.Home}/>
  }

  return (
    <div className={styles.ArticlesScreen}>
      <h1>Welcome to Hubrary!</h1>
      Feel free to search for your favorite writers and start reading!
    </div>
  );
}

