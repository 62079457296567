import AvatarImage from "../../AvatarImage/AvatarImage";
import styles from './NotificationsContainer.module.scss';
import loadingGif from '../../../assests/gif/loader.gif'
import NotificationBlock from "../../NotificationBlock/NotificationBlock";
import { NotificationType } from "../../../data/types";

export interface NotificationsContainerProps{
    notifications: NotificationType[],
    loading?: boolean,
    onEnter?: () => void;
}

export default function NotificationsContainer({notifications, loading = false, onEnter}: NotificationsContainerProps) {    

    return (<div className={styles.notificationContainer}>
        {loading? 
        <AvatarImage radius={20} className={styles.postLoadingGif} srcImg={loadingGif}/>:
        <div>
            {notifications.length > 0? 
            notifications.sort((a, b) => a.creation_time > b.creation_time? -1: 1).map(item => <NotificationBlock notification={item} onEnter={onEnter}/>):
            <p className={styles.noNotificationsBlock}>No notifications</p>}
        </div>}
    </div>);
}
