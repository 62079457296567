import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiRightArrow } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Descendant } from "slate";
import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import Card from "../../components/basic/Card/Card";
import Input from "../../components/basic/Input/Input";
import HubScreen from "../../components/HubScreen/HubScreen";
import PostCreationCard from "../../components/PostCreationCard/PostCreationCard";
import { User } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { AlignOptions, PostContent } from "../../utils/types";
import styles from "./NewForumScreen.module.scss";

const initialValue = [
  {
    type: "paragraph",
    align: "left" as AlignOptions,
    children: [{ text: "" }],
  },
];

export default function NewForumScreen() {

  const { id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const defaultTitle = urlParams.get('title')

  const user = useContext(UserLogged) as User;
  const [postLoading, setPostLoading] = useState<boolean>(false);

  const { t } = useTranslation();

    const navigate = useNavigate();
  const { apiHelper } = useContext(AccountContext) as AccoutContextType;

  const [document, setDocument] = useState<Descendant[]>(initialValue);
  const [title, setTitle] = useState<string>(defaultTitle ?? '');

  const onSubmit = useCallback((content: PostContent) => {
    if (!id) return new Promise(() => {});

    return apiHelper.addForumPost({content, owner: id, title: title})
    .then(() => navigate(-1));
  }, [id, title])

  const submit = () => {
    setPostLoading(true);
    onSubmit(document as PostContent).then(() => {
      console.log("post added");
    }).catch(console.error).finally(() => setPostLoading(false));
  }

  return (
    <div className={styles.forumPostScreen}>
      <Input placeholder={t('enter_forum_title_label')} className={styles.titleInput} value={title} onChange={setTitle} maxLength={70}/>

      <Card className={styles.postCard}>
        <PostCreationCard document={document} setDocument={setDocument} />

        <div className={styles.butonsContainer}>
          <Button 
            className={styles.postSubmitButton} 
            variant={ButtonVariant.Contained} 
            color={ButtonColor.Black} 
            onClick={submit} 
            isLoading={postLoading}>
              <BiRightArrow/>
          </Button>
        </div>
      </Card>
        

      {/* <PostCreationCard
        postContentReady={document}
        className={styles.createForumCard}
        onSubmit={onSubimt}
      /> */}
    </div>
  );
}
