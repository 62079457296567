import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Forum, User } from "../../data/types";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { UserLogged } from "../../navigation/AppRouter";
import Card from "../../components/basic/Card/Card";
import styles from './ForumListCard.module.scss'
import loadingGif from '../../assests/gif/loader.gif'
import Option from "../../components/basic/OptionsDropdown/Option/Option";
import OptoinsDropdown from "../../components/basic/OptionsDropdown/OptionsDropdown";
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import Link from "../../components/basic/Link/Link";

export interface ForumListCardProps {
    item: Forum,
    deleteForum: (id: string) => Promise<void> | void,
    deleteForumFetching: boolean,
}


export default function ForumListCard({item, deleteForum, deleteForumFetching}: ForumListCardProps) {

    const { t } = useTranslation();
    const navigate = useNavigate();  
    const my_user = useContext(UserLogged) as User;
    const { id } = useParams();

    if (!id) {
        return <></>;
    }

    return <div className={styles.forumCard} onClick={() => navigate(MainNavigationKeys.Forum + `/${item.id}`)}>
        <Card>
            <OptoinsDropdown width={200} className={styles.dropdownMenu}>
            <Option>{t('report_forum')}</Option>
            {id.toString() === my_user.id.toString() && 
            <Option onClick={() => deleteForum(item.id)}>
                {deleteForumFetching? <AvatarImage srcImg={loadingGif} radius={10}/> : t('delete_forum')}
            </Option>}
            </OptoinsDropdown>
            <div className={styles.forumCardContainer}>
                <div className={styles.forumCardTitle}>
                <Link>{item.title}</Link>
                </div>
                <div className={styles.forumCardFooter}>
                <span>{t('opened_by')}: <Link link={MainNavigationKeys.Profile + `/${item.authorId}`}>{item.authorName}</Link></span>
                <span>{t('responses_count')}: {item.comments.length}</span>
                </div>                    
            </div>
        </Card>
    </div>
}
