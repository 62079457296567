import { useContext } from "react";
import { Forum } from "../../../data/types";
import { MainNavigationKeys } from "../../../navigation/app/MainNavigationKeys";
import { AccountContext, AccoutContextType } from "../../../utils/Account";
import { TableWithEmotionsType } from "../../../utils/api/ApiTypes";
import AvatarImage from "../../AvatarImage/AvatarImage";
import Button, { ButtonVariant } from "../../basic/Button/Button";
import Link from "../../basic/Link/Link";
import EmotionContainer from "../../EmotionContainer/EmotionContainer";
import styles from "./ForumComment.module.scss"
import ContantRender from "../../ContentCard/ContantPaging/ContantPaging";


const COMMENTS_LENGTH_SHOW_DEFAULT = 10;

export interface ForumCommentProps {
    post: Forum,
    maxVisable: number, 
    setMaxVisable: (value: number) => void,
    setComments: (newComments: (Forum | string)[]) => void
}

export default function ForumComment({
    post,
    maxVisable,
    setMaxVisable,
    setComments,
}: ForumCommentProps){
  
    let visable: Forum[] = post.comments.filter(comment => typeof comment !== 'string') as Forum[];    

    return <>
      {[post, ...visable].map((comment, i) => 
        <div key={comment.id} className={styles.comment}>
          <div className={styles.commentFooter}>
            <div className={styles.postForumer}>
              <AvatarImage srcImg={comment.authorImage} radius={15}/>
              <div>
                <Link link={MainNavigationKeys.Profile + "/" + comment.authorId}>{comment.authorName}</Link><br/>
                {comment.creationTime.toDateString()}
              </div>
            </div>
          </div>
          <div className={styles.commentContent}>
            {i === 0? 
            <ContantRender content={comment.contant}/> // Main forum content
            : comment.contant.map(con => con.children.map(child => child.text.split("\n").map(p => <p>{p}</p>)))}
            <EmotionContainer 
                className={styles.likesContainer} 
                emotions={comment.emotions} 
                post_id={comment.id} 
                setEmotions={() => {}}
                type={TableWithEmotionsType.Forums}
            />
          </div>
        </div>)
      }
    </>
  }
  