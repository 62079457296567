import styles from './CommentsGroup.module.scss'

import { Post, User } from "../../../../data/types";
import Comment from '../Comment/Comment';
import Link from '../../../basic/Link/Link';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AccountContext, AccoutContextType } from '../../../../utils/Account';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { UserLogged } from '../../../../navigation/AppRouter';


export interface CommentsGroupProps {
    comments: (Post | string)[], 
    setComments: (newComments: (Post | string)[]) => void
    minVisable: number, 
    addMargine?: boolean
}

export function CommentsGroup({
    comments,
    minVisable,
    setComments,
    addMargine = false
}: CommentsGroupProps){
    const { t } = useTranslation();

    const [visableCount, setVisableCount] = useState<number>(minVisable);
    const [fetching, setFetching] = useState<boolean>(false);

    const { apiHelper } = useContext(AccountContext) as AccoutContextType;
    const user = useContext(UserLogged) as User;

    let fetchedComments: Post[] = comments.filter(comment => typeof comment !== 'string') as Post[];
    let visable = fetchedComments.slice(0, visableCount);

    useEffect(() => {
      setVisableCount(fetchedComments.length)
    }, [fetchedComments.length])

    const deleteCommentFunction = (commentId: string) => {
      return async () => {
        await apiHelper.deletePost({post_id: commentId})

        setComments(comments.filter(comment => typeof comment === 'string' || comment.id !== commentId))
      }
    }

    const showMore = useCallback(async (len: number) => {
        if (fetching) return;

        setFetching(true);

        try{
          len = Math.min(len, comments.length);          
          if (len > fetchedComments.length){
              const commentsThatAreStillUnfetched: string[] = comments.filter((c) => typeof c === 'string') as string[];
              const numberToFetch = len - fetchedComments.length;
              
              const post_ids = commentsThatAreStillUnfetched.slice(0, numberToFetch) as string[];              
              const newComments: Post[] = (await apiHelper.getPostsByIdsAPI({ids: post_ids, get_comments: false})).posts
      
              let newArray: (Post | string)[] = visable;
              newArray = newArray.concat(newComments).concat(commentsThatAreStillUnfetched.slice(numberToFetch));

              setComments(newArray);
          }

          setVisableCount(len);
        } catch (error) {
            console.error(error);
        } finally {
          setFetching(false);
        }
        
    }, [apiHelper, comments, fetchedComments, visable, fetching]);
  
    const margineClass = addMargine? styles.withMargine : undefined;    

    return (<div className={classnames(styles.commentsGroup, margineClass)}>
      
      {visable.map((comment, i) => {
        const setComment = (data: Post) => {
          setComments([...comments.slice(0, i), data, ...comments.slice(i + 1)])
        }
        return <Comment 
          data={comment} 
          key={comment.id} 
          setData={setComment} 
          deleteComment={comment.authorId.toString() === user.id.toString()? deleteCommentFunction(comment.id) : undefined}
        />
      })}
      
      <div>
      {comments.length > visableCount && 
      (fetching? <span>{t('loading_btn')}</span> : 
      <Link onClick= {() => { showMore(visableCount + 4) }}>
          {t('show_more_comments')}
      </Link>)}
      <span>{"    "}</span>
      {visable.length > minVisable && 
        <Link
          onClick= {() => setVisableCount(Math.max(visableCount - 4, minVisable))}
        >
          {t('show_less_comments')}
        </Link>
      }
      </div>
    </div>);
  }
  
  