import { SavedPost } from "../../../data/types";
import { MdDelete } from 'react-icons/md'
import styles from "./SavedPostTable.module.scss";

export interface savedPostsTableProps {
    savedPosts: SavedPost[] | undefined;
    setPost: (obj: SavedPost | undefined) => void;
    deleteSavedPost: (id: string, index: number) => void;
}

export default function SavedPostsTable({savedPosts, setPost, deleteSavedPost} : savedPostsTableProps) {
    const tableHeader = (
        <tr><th>Contant</th><th>Last Update</th><th></th><th></th></tr>
    );
    
    let tableRows = <tr>loading..</tr>
    if (savedPosts) {
        tableRows = (<>
            {savedPosts.map((post, index) => {
                return <tr className={styles.savedPostRow}>
                    {/* <td>{post.id}</td> */}
                    <td onClick={() => setPost(post)}>{post.contant[0].children[0].text.slice(0, 40)}...</td>
                    <td>{post.modificationTime.toDateString()}</td>
                    <td onClick={() => deleteSavedPost(post.id, index)}><MdDelete/></td>
                </tr>
            })} 
            <tr className={styles.savedPostRow}><td onClick={() => setPost(undefined)}>New</td><td></td><td></td></tr>
        </>);
    }

  return (
    <table className={styles.grid}>
        <thead className={styles.headerRow}>
            {tableHeader}
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>
  );
}
