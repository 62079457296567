import { useNavigate } from "react-router-dom";
import styles from "./ChangePasswordScreen.module.scss";
import authStyles from "../AuthenticationForm.module.scss";
import { useContext, useState } from "react";
import logo from "../../../assests/images/LogoDark.png";
import { AccountContext, AccoutContextType } from "../../../utils/Account";
import { MainNavigationKeys } from "../../../navigation/app/MainNavigationKeys";
import { UserLogged } from "../../../navigation/AppRouter";
import { User } from "../../../data/types";


export default function ChangePasswordScreen() {

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");

  const my_user = useContext(UserLogged) as User;

  const navigate = useNavigate();

  const { changePassword } = useContext(AccountContext) as AccoutContextType;
  const submit = (event: any) => {
    event.preventDefault();

    if (confirm !== newPassword){
        console.error("Confirm must be equal to password.")
        return;
    }

    changePassword(oldPassword, newPassword);

    navigate(MainNavigationKeys.Profile + `/${my_user.id}`)
  };

  return (
    <div className={authStyles.fullPage}>
      <form className={authStyles.authForm}>
          <div className={authStyles.logo}><img src={logo} alt=""/></div>
          <div className={authStyles.container}>
          <label form="psw">
              <b>Old Password</b>
          </label>
          <input
              type="password"
              placeholder="Enter Old Password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              required
          />

          <label form="psw">
              <b>New Password</b>
          </label>
          <input
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              required
          />

            <label form="psw">
              <b>Confirm New Password</b>
          </label>
          <input
              type="password"
              placeholder="Confirm New Password"
              value={confirm}
              onChange={(event) => setConfirm(event.target.value)}
              required
          />

          <button
              className={authStyles.submitButton}
              type="submit"
              onClick={submit}
          >
              {"Change Password"}
          </button>
          <button className={authStyles.secondaryBtn} onClick={() => navigate(-1)}>
              {"Cencel"}
          </button>
          </div>
      </form>
    </div>
  );
}