import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import Option from "../../components/basic/OptionsDropdown/Option/Option";
import OptoinsDropdown from "../../components/basic/OptionsDropdown/OptionsDropdown";
import styles from "./TestingComponents.module.scss"


export default function TestingComponents() {
  return (
    <div className={styles.testScreen}>
      <OptoinsDropdown width={200}>
        <Option>Remove Comment</Option>
        <Option noHover><Button color={ButtonColor.Red} variant={ButtonVariant.Contained}>Delete</Button></Option>
      </OptoinsDropdown>
    </div>
  );
}