import classnames from 'classnames';
import React, { Children, ReactElement } from 'react';
import styles from './Tabs.module.scss'


export interface TabsProps {
    activeLabel: any;
    setActiveLabel: (activeLabel: any) => void;
    children?: React.ReactNode;
    headersClassName?: string;
}

export default function Tabs({children, activeLabel, setActiveLabel, headersClassName} : TabsProps){

    const activeTab = children? Children.toArray(children).find(
        (child) => (child as ReactElement).props.label === activeLabel
    ) as ReactElement : undefined;

    return (
        <div className={styles.tabContainer}>
            <div className={styles.tabsHeaders}>
                {Children.map(children, (child) => {        
                    child = child as ReactElement;
                    let headerClass = classnames(styles.tabHeader, headersClassName);
                    headerClass = child.props && child.props.label === activeLabel? classnames(headerClass, styles.activeTabHeader) : headerClass;

                    return (
                    <div 
                        className={headerClass} 
                        onClick={() => setActiveLabel((child as ReactElement).props.label)}
                    >
                        {child.props.label}
                    </div>);
                })}
            </div>

            <>{activeTab && activeTab.props.children}</>

        </div>
    );
}