import styles from "./HomeScreen.module.scss";
import { useContext, useEffect, useState } from "react";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { Post, PostTopic, User } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import HorizontalCollection from "../../components/HorizontalCollection/HorizontalCollection";
import { useIsMobile } from "../../utils/general/IsMobile";
import FeedLoader from "../../components/basic/FeedLoader/FeedLoader";
import MobileBottomTabs from "../../components/basic/MobileBottomTabs/MobileBottomTabs";
import { PostBlock } from "../../components/PostBlock/PostBlock";
import { Navigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { getKeyByValue } from "../../utils/general/enums";

export default function HomeScreen() {

  const [error, setError] = useState<string>()  
  const [subs, setSubs] = useState<User[]>([])
  const [topics, setTopics] = useState<({[key: string]: string})>({});
  const [collectionEmpty, setCollectionEmpty] = useState<{[key: string]: boolean}>({});

  const { apiHelper } = useContext(AccountContext) as AccoutContextType;
  const user = useContext(UserLogged) as User;  

  const isMobile = useIsMobile();

  useEffect(() => {
    if (user){
      // Fetch subs
      apiHelper.getFollowersAPI({})
      .then(res => {
        setSubs(res.followers)
      })
      .catch((err) => {
        console.error(err);
        setError("problem in fetching subs")
      });
    }
  }, [user, apiHelper])

  useEffect(() => {
    if (user && user.topics){
      setTopics(Object.fromEntries(user.topics.map(topic => ([topic, topic]))))
    } else {
      setTopics({})
    }
  }, [user])

  if (error) {
    return <ErrorScreen message={error ?? "Unknown Error"}/>;
  }

  // const sidebar = (<SideBar authors={subs}/>);

  function getFetchFunction(topic?: PostTopic, writers?: string[], hot?: boolean) {
    return async (limit: number, offset: number) => {
      const request = {posts_limit: limit, offset: offset, topic: topic, writers}
      // const func = hot? apiHelper.getHotPostsAPI : apiHelper.getPostsAPI;
      if (hot) {
        return apiHelper.getHotPostsAPI(request).then(res => res.posts as Post[]);
      }

      return apiHelper.getPostsAPI(request).then(res => res.posts as Post[]);
    }
  }

  let tabs: {[key: string]: any} = {
    Popular: getFetchFunction(undefined, undefined, true),
  };

  if (user && user.followers.length > 0) {
    tabs = {
      ...tabs,
      Following: getFetchFunction(undefined, user.followers.map(f => f.id), false),
      ...Object.fromEntries(Object.entries(topics).map(([name, topic]) => ([
        getKeyByValue(PostTopic, name), 
        getFetchFunction(topic as PostTopic, user.followers.map(f => f.id), true)
      ])))
    }
  }

  return (
    <div className={styles.ArticlesScreen}>
      {(user && user.followers.length === 0) && 
      <>
        <h1>Welcome to Hubrary!</h1>
        Feel free to search for your favorite writers and start reading!
      </>
      }
      {isMobile? 
      <MobileBottomTabs tabs={Object.fromEntries(Object.entries(tabs).map(([name, fetchFunc]) => [name, <FeedLoader ElementComponent={PostBlock} breakingWidth={320} fetchPosts={fetchFunc}/>]))}/>
      : 
      Object.entries(tabs).map(([name, fetchFunc]) => {
        if (collectionEmpty[name as string]) return <></>
        return (<>
          <h1 className={styles.headingRight}>{name}</h1>
          <HorizontalCollection 
            // setEmpty={(empty) => setCollectionEmpty({...collectionEmpty, [topic as string]: empty})} 
            fetchPosts={fetchFunc} 
            ElementComponent={PostBlock} breakingWidth={270}/>
        </>)
      })
      }
    </div>
  );
}

