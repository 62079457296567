import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { MainNavigationKeys } from '../../navigation/app/MainNavigationKeys';
import { LoginStatus } from '../../strore/SessionAuth';
import { RootState } from '../../strore/store';
import styles from './LandingScreen.module.scss'
import logo from '../../assests/images/LogoBright.png';
import logoIcon from '../../assests/icons/icon.svg'
import Button, { ButtonColor, ButtonVariant } from '../../components/basic/Button/Button';

export interface LandingScreenProps {

}

export default function LandingScreen({}: LandingScreenProps) {
    const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)
    const navigate = useNavigate();

    if (loginStatus === LoginStatus.LOGGED_IN){
        return <Navigate to={MainNavigationKeys.Home}/>
    }

    return (<div className={styles.fullPage}>
        <div className={styles.top}>
            <div>
                <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat}>Writers</Button></div>
                <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat}>Readers</Button></div>
            </div>
            <div>
                <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat} onClick={() => navigate(MainNavigationKeys.Login)}>Login</Button></div>
                <div><Button color={ButtonColor.Black} variant={ButtonVariant.Contained} onClick={() => navigate(MainNavigationKeys.Register)}>Sign Up</Button></div>
            </div>
        </div>
        <div className={styles.middle}>
            <div className={styles.logo}><img src={logo} /></div>
            Introducing the newest platform for aspiring writers and comunity readers.
        </div>
        <div className={styles.buttom}>
            <div className={styles.iconBtn}><img src={logoIcon}/></div>
            <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat}>About</Button></div>
            <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat}>Stories</Button></div>
            <div><Button color={ButtonColor.Black} variant={ButtonVariant.Flat}>Contact</Button></div>
        </div>
    </div>);
}