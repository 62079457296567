import { useCallback, useEffect, useRef, useState } from "react";
import { PostContent, PostContentParagraph, PostContentParagraphChildren } from "../../utils/types";
import Link from "../basic/Link/Link";
import ContantRender from "../ContentCard/ContantPaging/ContantPaging";
import styles from "./PostContentRender.module.scss"

export interface PostContentRenderProps {
    content: PostContent,
    maxHeight?: number
}

export default function PostContentRender({ content, maxHeight } : PostContentRenderProps) {

    const [page, setPage] = useState(0);
    const [pageInput, setPageInput] = useState(page+1);
    const [pageSplit, setPageSplit] = useState<number[] | undefined>(undefined);
    const [maxHeightState, setMaxHeight] = useState(maxHeight ?? window.innerHeight - 200);

    const fullContantContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const calculatePages = useCallback(() => {          
        if (!fullContantContainerRef.current) {
            return;
        }
                
        let pageIndexes = [0];
        const currentMaxHeight = maxHeight ?? window.innerHeight - 200;                
        setMaxHeight(currentMaxHeight);

        const children = fullContantContainerRef.current.children;
        let totalHeight = 0;        
        for (let i = 0; i < children.length; i ++){
            const childHeight = (children[i] as HTMLElement).offsetHeight; // TODO : including padding, not includding margin                       
            if (childHeight > currentMaxHeight){
                // TODO : split the paragraph
                // const text = children[i].textContent as string;
                // children[i].textContent = text.slice(0, text.length / 2) + '\n' + text.slice(text.length / 2)
                // console.log(children[i].textContent);
            }

            if (childHeight + totalHeight > currentMaxHeight){
                
                pageIndexes = pageIndexes.concat(i);
                totalHeight = 0;
            }

            totalHeight += childHeight;
        }

        setPageSplit(pageIndexes);
    }, []);

    useEffect(() => {
        calculatePages();
        window.addEventListener("resize", calculatePages)
        return () => window.removeEventListener("resize", calculatePages)
    }, [calculatePages, fullContantContainerRef.current])

    const setPageAndInput = (value: number) => {
        setPage(value);
        setPageInput(value + 1);
    }

    return (
    <div style={{position: "relative", padding: 0, margin: 0}} className={styles.container}>
        <div ref={fullContantContainerRef} style={{visibility: "hidden", position: "absolute", maxWidth: "100%", width: '100%'}}>
            <ContantRender content={content}/>
        </div>

        {pageSplit !== undefined &&
        <div className={styles.postContentRenderer} style={{height: pageSplit.length > 1? maxHeightState : undefined}}>
            <ContantRender content={content.slice(pageSplit[page], pageSplit[page + 1])}/>
        </div>} 
        {pageSplit && pageSplit.length > 1 &&
        <div className={styles.movePageContainer}>
            {page > 0 ? <Link onClick={() => setPageAndInput(Math.max(page - 1, 0))} >{"<-"}</Link> : <div></div>}
            <input className={styles.currentPageIndicator} value={pageInput} 
                onChange={event => setPageInput(Number(event.target.value))} 
                onBlur={() => setPageAndInput(Math.min(pageSplit.length - 1, Math.max(0, pageInput - 1)))}/>
            {page < pageSplit.length - 1 ? <Link onClick={() => setPageAndInput(Math.min(page + 1, pageSplit.length - 1))}>{"->"}</Link> : <div></div>}
            {/* <button onClick={() => setPage(Math.min(page + 1, pageSplit.length - 1))} disabled={page >= pageSplit.length - 1}>{"->>"}</button> */}
        </div>}
    </div>);
}

