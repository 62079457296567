

export interface TabContentProps {
    label: any;
    children?: React.ReactNode;
}

export default function Tab({
    label,
    children
}: TabContentProps) {

    return (<>children</>);
}