import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainNavigationKeys } from "../../../navigation/app/MainNavigationKeys";
import { AccountContext, AccoutContextType } from "../../../utils/Account";
import authStyles from '../AuthenticationForm.module.scss';
import styles from './ConfirmForgotPasswordScreen.module.scss';
import logo from "../../../assests/images/LogoDark.png";
import loadingGif from '../../../assests/gif/loader.gif'
import AvatarImage from "../../../components/AvatarImage/AvatarImage";


export default function ConfirmForgotPasswordScreen(){

    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [sended, setSended] = useState<boolean>(false);


    const [username, setUsername] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>('');

    const navigate = useNavigate()
    const { forgotPassword, confirmForgotPassword } = useContext(AccountContext) as AccoutContextType;

    const send = () => {
        if(!username) return;

        setLoading(true);
        forgotPassword(username).then(() => {
            setSended(true);
        }).catch((err) => {
            setError(err.message)
        })
        .finally(() => setLoading(false));
    }

    const confirm = () => {
        if(!username) return;
        if (newPassword !== newConfirmPassword) {
            setError('passwords are not the same');
            return;
        }

        setLoading(true);
        confirmForgotPassword(username, code, newPassword).then(() => {
            navigate(MainNavigationKeys.Login);
        }).catch((err) => {
            setError(err.message)
        })
        .finally(() => setLoading(false));
    }

    return (
    <div className={authStyles.fullPage}>
        <div className={authStyles.authForm}>
            <img className={authStyles.logo} src={logo} alt=""/>
            <div className={authStyles.container}>
                <label>
                    <b>Username:</b>
                    <input value={username} onChange={(event) => setUsername(event.target.value)} type="text"/>
                </label>
                <button type="submit" className={authStyles.secondaryBtn} onClick={send}>send email</button>
                
                {sended && 
                <>
                <hr className={styles.hr} />
                <label>
                    <b>Code:</b>
                    <input value={code} onChange={(event) => setCode(event.target.value)} type="text"/>
                </label>
                <label>
                    <b>New Password:</b>
                    <input value={newPassword} onChange={(event) => setNewPassword(event.target.value)} type="password"/>
                </label>
                <label>
                    <b>Confirm Password:</b>
                    <input value={newConfirmPassword} onChange={(event) => setNewConfirmPassword(event.target.value)} type="password"/>
                </label>

                <button className={authStyles.submitButton} onClick={confirm}>
                    {loading?  <AvatarImage radius={10} srcImg={loadingGif}/> : "Confirm"}
                </button>
                </>}
                {error && <div className={authStyles.errorMessage}>{error}</div>}
            </div>
        </div>
    </div>
    );
}