import { Provider } from "react-redux";
import "./App.css";
import { AppRouter } from "./navigation/AppRouter";
import store from "./strore/store";
import { Account } from "./utils/Account";
import './utils/i18next/i18n';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en')
    document.body.dir = i18n.dir();
  }, [])

  return (
    <Provider store={store}>
      <Account>
        <AppRouter />
      </Account>
    </Provider>
  );
}

export default App;
