import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import { User, UserRole } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import styles from './ProfileDetailsCard.module.scss'
import personImage from '../../assests/images/person.png'
import { FaHome, FaMailBulk } from "react-icons/fa";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import { useIsMobile } from "../../utils/general/IsMobile";
import classnames from "classnames";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Link from "../../components/basic/Link/Link";
import { FiCamera, FiUpload } from "react-icons/fi";
import loadingGif from '../../assests/gif/loader.gif'




export interface ProfileDetailsCardProps {
    user: User,
  } 
  
export default function ProfileDetailsCard({user}: ProfileDetailsCardProps){
const { t } = useTranslation();
const navigation = useNavigate();

const [fetchFollow, setFetchFollow] = useState<boolean>(false);
const [fileUpload, setFileUpload] = useState<File>();
const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);

const { apiHelper } = useContext(AccountContext) as AccoutContextType;
const my_user = useContext(UserLogged) as User;

const isMobile = useIsMobile();

const addFollow = useCallback(() => {
    if (!user.id || fetchFollow) {
    return 
    }

    setFetchFollow(true);
    apiHelper.addFollow({author_id: user.id})
    .then(() => {
    window.location.reload();
    })
    .finally(() => setFetchFollow(false));
}, [user.id, apiHelper, fetchFollow]);

const removeFollow = useCallback(() => {
    if (!user.id || fetchFollow) {
    return 
    }

    setFetchFollow(true);
    apiHelper.removeFollow({author_id: user.id})
    .then(() => {
    window.location.reload();
    })
    .finally(() => setFetchFollow(false));
}, [user.id, apiHelper, fetchFollow]);

const uploadImage = useCallback(() => {
    if (!fileUpload) return;

    setFileUploadLoading(true);
    const data = new FormData();
    data.append('file', fileUpload, fileUpload.name)          
    apiHelper.uploadImage({file: data})
    .then(() => window.location.reload())
    .finally(() => setFileUploadLoading(false));

}, [fileUpload, apiHelper]);

return (
    <div className={styles.profilePhotoContainer}>
        <div className={styles.head}>
            <h1 className={classnames(styles.profileNameHeader, isMobile && styles.mobile)}>{`${user.firstName} ${user.lastName}`}</h1>
            {user.id === my_user.id && 
            <Button variant={ButtonVariant.Flat} color={ButtonColor.Black} onClick={() => navigation(MainNavigationKeys.EditProfile)}><BiEditAlt/>Edit proflie</Button>}
        </div>

        <div className={classnames(styles.profileDetails, isMobile && styles.mobile)}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <AvatarImage srcImg={user.image ?? personImage} radius={100}/>
                {user.id === my_user.id && <>
                    <label>
                        <input style={{display: 'none'}} type="file" accept="image/jpeg" onChange={(event) => {if(event.target.files) setFileUpload(event.target.files[0])}} />
                        <Link><FiCamera/> Edit picture</Link>
                    </label>
                    {fileUpload && <>
                        {fileUploadLoading? <AvatarImage srcImg={loadingGif} radius={10}/>:
                        <Link onClick={uploadImage}><FiUpload/>Upload "{fileUpload.name}"</Link>}
                    </>}
                </>}
            </div>
            <div className={styles.profileInfo}>
                <h3>Bio:</h3>
                <div className={styles.bio}>{user.bio}</div>
                {/* {user.followers.length > 0 && <div className={styles.profileInfoRow}> <FaBookReader/>
                    {user.followers.slice(0, 3).map((sub) => {
                        return <Link link={MainNavigationKeys.Profile + `/${sub.id}`}>{`${sub.first_name} ${sub.second_name}`}</Link>
                    })} 
                    {user.followers.length - 3 > 0 && t('and_x_others').replace('{0}', (user.followers.length - 3).toString())}
                </div>}  */}
                {/* <div> <FaUserFriends/> Yedidya Frundlich, Rebecca Wisse and 500 others</div>  */}
            </div>

            {user.role === UserRole.AUTHOR && user.id !== my_user.id && 
                (!(my_user.followers.map(sub => sub.id.toString()).includes(user.id)) ?
                <Button 
                    className={styles.followBtn} 
                    variant={ButtonVariant.Contained} 
                    color={ButtonColor.Blue}
                    onClick={addFollow}
                    isLoading={fetchFollow}
                    >
                    {t('follow_btn')}
                </Button> :
                <Button 
                className={styles.followBtn} 
                variant={ButtonVariant.Contained} 
                color={ButtonColor.Gray}
                onClick={removeFollow}
                isLoading={fetchFollow}
                >
                    {t('unfollow_btn')}
                </Button>)
            }
        </div>
        {user.followedBy.length > 0 && <div className={styles.profileFollowers}>
            Followed by: 
            {user.followedBy.slice(0, 3).map(u => <><Link className={styles.followerCard} link={`${MainNavigationKeys.Profile}/${u.id}`}>{u.first_name} {u.second_name}</Link>{', '}</>)}
            {user.followedBy.length > 3 && `and ${user.followedBy.length - 5} others`}
        </div>}
    </div>);
}

  