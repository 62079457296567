import { PostContent, PostContentParagraph } from "../../../utils/types";
import { renderElement, renderLeaf } from "../../TextEditor/EditorUtils";
import styles from './ContantPaging.module.scss'

export interface ContantRenderProps {
    content: PostContent,
}

export default function ContantRender({ content } : ContantRenderProps) {

    return (<>
        {content.map((paragraph, i) => {
            return <div className={styles.wrapper} id={i.toString()}>{renderParagraph(paragraph)}</div>
        })}
    </>);
}

function renderParagraph(content: PostContentParagraph) {

    return renderElement({
        element: content,
        attributes: {'data-slate-node': "element", ref: undefined},
        children: content.children.map(leaf => renderLeaf({
            children: leaf.text,
            leaf: leaf,
            text: leaf,
            attributes: {'data-slate-leaf': true}
        }))
    })
}
