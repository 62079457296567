import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import styles from "./ErrorScreen.module.scss";


export interface ErrorScreenParams {
    message: string
}

export default function ErrorScreen({ message }: ErrorScreenParams){

    return ( 
        <div className={styles.fullPage}>
            {message}
            <Button 
                variant={ButtonVariant.Contained} 
                onClick={() => window.location.reload()} 
                color={ButtonColor.Gray}
            >
                Refresh
            </Button>
        </div>
    );
}