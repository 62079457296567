import { Forum, Post, SavedPost, User } from "../../data/types";
import { addCommentRequest, addCommentResponse, getPostsRequest, getPostsResponse, getSubsRequest, getSubsResponse, getMyUserRequest, getMyUserResponse, getProfileRequest, getProfileResponse, NEED_TO_COMPLETE_DETAILS, addUserRequest, addUserResponse, addPostRequest, addPostResponse, updateUserRequest, updateUserResponse, uploadImageRequest, uploadImageResponse, setEmotionRequest, setEmotionResponse, getForumsRequest, getForumsResponse, addForumRequest, addForumResponse, getForumsByIdsRequest, getForumsByIdsResponse, TableWithEmotionsType, addFollowRequest, addFollowResponse, getPostsByIdsRequest, getPostsByIdsResponse, searchRequest, searchResponse, searchResult, getFollowersRequest, getFollowersResponse, savePostRequest, savePostResponse, getSavedPostsRequest, savedPostsResponse, deleteSavedPostRequest, deleteSavedPostResponse, deletePostRequest, deletePostResponse, deleteForumRequest, deleteForumResponse, analyticReportRequest, getUserNotificationsRequest, getUserNotificationsResponse, deactivateNotificationRequest, deactivateNotificationResponse, getSuggestoinsRequest, getSuggestionsResponse, addSuggestionRequerst, addSuggestionResponse, updateSuggestionEmotionRequest, updateSuggestionEmotionResponse } from "./ApiTypes";


// Users
const GET_USER_API = "/users/get";
const ADD_USER_API = "/users/add"
const UPDATE_USER_API = "/users/update"
const GET_PROFILE_API = "/users/public";
const UPLOAD_IMAGE_API = "/users/upload/profile";
const GET_USER_NOTIFICATIONS = "/users/notifications";
const DEACTIVATE_NOTIFICATION = "/users/deactivate_notifications";

// Suggestions
const GET_SUGGESTOINS_API = '/suggestions';
const ADD_SUGGESTION_API = '/suggestions/add';
const DELETE_SUGGESTION_API = '/suggestions/delete';
const ADD_SUGGESTIONS_EMOTOIN_API = '/suggestions/emotions/update';

// Subscribers
const GET_SUBS_API = "/users/subscribers";

// Search
const GET_SEARCH_API = "/users/search"

// Follow
const GET_FOLLOWERS_API = "/users/followers";
const ADD_FOLLOW = "/users/followers/add";
const REMOVE_FOLLOW = "/users/followers/unfollow";

// Posts
const GET_POSTS_BY_IDS_API = "/posts/by_ids";
const GET_POSTS_API = "/posts";
const GET_HOT_POSTS_API = "/posts/hot";
const ADD_POST_API = "/posts/add";
const REMOVE_POST_API = "/posts/delete"
const SET_EMOTION_API = "/posts/update_emotion"

// Saved Posts
const GET_SAVED_POSTS = "/posts/saved"
const SAVE_POST = "/posts/saved/add"
const UPDATE_SAVED_POST = "/posts/saved/update"
const DELETE_SAVED_POST =  "/posts/saved/delete"

// Forums
const GET_FORUMS_API = "/forums/by_writer";
const GET_FORUMS_BY_ID_API = "/forums/by_ids";
const SET_EMOTION_FORUMS_BY_ID_API = "/forums/update_emotion";
const ADD_FOROM_API = "/forums/add";
const DELETE_FORUM_API = "/forums/delete"

// Analitics
const REPORT_ANALYTIC = '/analytics/add'

export const EDITOR_TOOLBAR_BUTTONS_PRESS_ANALYTIC_TYPE = 'EDITOR_TOOLBAR_BUTTONS_PRESS';
export const EDITOR_TOOLBAR_KEYBOARD_SHORTCUT_ANALYTIC_TYPE = 'EDITOR_TOOLBAR_KEYBOARD_SHORTCUT';


export class ApiHelper{
    fetchData: (input: RequestInfo, params?:{}, headers?: {}, body?: {}, method?: string, withoutContentType?: boolean) => Promise<Response>;

    constructor(fetchData: (input: RequestInfo, params?:{}, headers?: {}, body?: {}, method?: string, withoutContentType?: boolean) => Promise<Response>){
        this.fetchData = fetchData;
    }

    async getSuggestions(request: getSuggestoinsRequest): Promise<getSuggestionsResponse> {
        // call fetchData of Account
        const res = await this.fetchData(GET_SUGGESTOINS_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }

        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        return {suggestions: obj};
    }

    async addSuggestion(request: addSuggestionRequerst): Promise<addSuggestionResponse> {
        // call fetchData of Account
        const res = await this.fetchData(ADD_SUGGESTION_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }

        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        return {suggestion_id: obj};
    }

    async likeSuggestion(request: updateSuggestionEmotionRequest): Promise<updateSuggestionEmotionResponse> {
        // call fetchData of Account
        const res = await this.fetchData(ADD_SUGGESTIONS_EMOTOIN_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }

        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        return {};
    }

    async getUserAPI(request: getMyUserRequest) : Promise<getMyUserResponse> {
        // call fetchData of Account
        // console.log('Fetching user..');
        const res = await this.fetchData(GET_USER_API, request);
        let obj = await this.getObjectFromResponse(res);

        obj = obj.result;

        if (obj === NEED_TO_COMPLETE_DETAILS) {
            return {status: NEED_TO_COMPLETE_DETAILS}
        }

        // return response
        return {user: this.obj2user(obj)};
    }

    async addUser(request: addUserRequest): Promise<addUserResponse> {
        // call fetchData of Account
        const res = await this.fetchData(ADD_USER_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async updateUser(request: updateUserRequest): Promise<updateUserResponse> {
        // call fetchData of Account
        const res = await this.fetchData(UPDATE_USER_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async uploadImage(request: uploadImageRequest): Promise<uploadImageResponse> {
        console.log(request);
        
        // call fetchData of Account
        return this.fetchData(
            UPLOAD_IMAGE_API, 
            {image_type: request.image_type ?? "PROFILE"}, 
            undefined, 
            request.file, 
            "POST",
            true
        );
    }

    async getProfile(request: getProfileRequest) : Promise<getProfileResponse> {
    
        const res = await this.fetchData(GET_PROFILE_API, request);
        let obj = await this.getObjectFromResponse(res);

        obj = obj.result;

        // return response
        return {user: this.obj2user(obj)};
    }

    async getPostsAPI(request: getPostsRequest) : Promise<getPostsResponse> {

        // call fetchData of Account
        // console.log('Fetching posts..');
        const res = await this.fetchData(GET_POSTS_API, undefined, undefined, JSON.stringify(request), 'POST');
        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;
        
        const result: Post[] = obj.map((post: any) => {
            return this.obj2post(post);
        })

        // return response
        return { posts: result }
    }

    async getHotPostsAPI(request: getPostsRequest) : Promise<getPostsResponse> {
        const res = await this.fetchData(GET_HOT_POSTS_API, undefined, undefined, JSON.stringify(request), 'POST');
        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;
        
        const result: Post[] = obj.map((post: any) => {
            return this.obj2post(post);
        })

        return { posts: result }
    }

    async getPostsByIdsAPI(request: getPostsByIdsRequest) : Promise<getPostsByIdsResponse> {


        const res = await this.fetchData(GET_POSTS_BY_IDS_API, undefined, undefined, JSON.stringify(request), 'POST');
        let obj = await this.getObjectFromResponse(res);
        if (!obj.result) {
            throw (obj.detail)
        }
        obj = obj.result;
        const result: Post[] = obj.map((post: any) => {
            return this.obj2post(post);
        })

        // return response
        return { posts: result }
    }

    async deleteForumAPI(request: deleteForumRequest): Promise<deleteForumResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(DELETE_FORUM_API, request, undefined, undefined, "DELETE");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {}
    }

    async getForumsAPI(request: getForumsRequest) : Promise<getForumsResponse> {

        // call fetchData of Account
        // console.log('Fetching posts..');
        const res = await this.fetchData(GET_FORUMS_API, undefined, undefined, JSON.stringify(request), 'POST');
        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        const result: Forum[] = obj.map((post: any) => {
            return this.obj2forum(post);
        })

        // return response
        return { forums: result }
    }

    async getForumsByIdsAPI(request: getForumsByIdsRequest) : Promise<getForumsByIdsResponse> {

        // call fetchData of Account
        // console.log('Fetching posts..');
        request.get_comments = request.get_comments ?? true;
        const res = await this.fetchData(GET_FORUMS_BY_ID_API, undefined, undefined, JSON.stringify(request), 'POST');
        let obj = await this.getObjectFromResponse(res);
        // obj = obj.result;
        
        const result: Forum[] = obj.result.map((post: any) => {
            return this.obj2forum(post);
        })

        // return response
        return { forums: result }
    }

    async addComment(request: addCommentRequest): Promise<addCommentResponse> {
        // call fetchData of Account
        const res = await this.fetchData(ADD_POST_API, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return { post_id: (await res.json()).result};
    }

    async addPost(request: addPostRequest): Promise<addPostResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(ADD_POST_API, undefined, undefined, JSON.stringify({...request}), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async deletePost(request: deletePostRequest): Promise<deletePostResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(REMOVE_POST_API, undefined, undefined, JSON.stringify({...request}), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async savePost(request: savePostRequest): Promise<savePostResponse>{
        let url = SAVE_POST;
        const request_content: any = {content: request.content};
        if (request.post_id){
            url = UPDATE_SAVED_POST;
            request_content.post_id = request.post_id;
        }

        const res = await this.fetchData(url, undefined, undefined, JSON.stringify(request_content), "POST");
        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        return {post_id: (await res.json()).result};
    }

    async getSavedPost(request: getSavedPostsRequest): Promise<savedPostsResponse>{
        const res = await this.fetchData(GET_SAVED_POSTS, undefined, undefined, JSON.stringify(request), 'POST');
        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        const result: SavedPost[] = obj.map((post: any) => {
            return this.obj2savedPost(post);
        })
        return {posts: result};
    }

    async deleteSavedPost(request: deleteSavedPostRequest): Promise<deleteSavedPostResponse>{
        const res = await this.fetchData(DELETE_SAVED_POST, undefined, undefined, JSON.stringify(request), "post");
        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        return {};
    }

    async addFollow(request: addFollowRequest): Promise<addFollowResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(ADD_FOLLOW, request, undefined, undefined, "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async removeFollow(request: addFollowRequest): Promise<addFollowResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(REMOVE_FOLLOW, request, undefined, undefined, "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async addForumPost(request: addForumRequest): Promise<addForumResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(ADD_FOROM_API, undefined, undefined, JSON.stringify({...request}), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }

        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        // TODO : return the id
        return {post_id: obj};
    }

    async search(request: searchRequest) : Promise<searchResponse> {

        // call fetchData of Account
        // console.log('Fetching posts..');
        const res = await this.fetchData(GET_SEARCH_API, request);
        let obj = await this.getObjectFromResponse(res);
        obj = obj.result;

        const result: searchResult[] = obj.map((res: any) => {
            return {
                id: res.id,
                first_name: res.first_name,
                last_name: res.second_name,
                role: res.user_type,
                profile_image: res.profile_image
            }
        })

        // return response
        return { results: result }
    }

    async setEmotion(request: setEmotionRequest, tableType?: TableWithEmotionsType): Promise<setEmotionResponse> {
        // call fetchData of Account    

        let uri = SET_EMOTION_API;
        if (tableType === TableWithEmotionsType.Forums) {
            uri = SET_EMOTION_FORUMS_BY_ID_API;
        }

        const res = await this.fetchData(uri, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async getUserNotificatoins(request: getUserNotificationsRequest): Promise<getUserNotificationsResponse> {
        // call fetchData of Account    

        const res = await this.fetchData(GET_USER_NOTIFICATIONS, request);

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return res.json().then(res => res.result);
    }

    async deactivateNotification(request: deactivateNotificationRequest): Promise<deactivateNotificationResponse> {
        // call fetchData of Account    
        const res = await this.fetchData(DEACTIVATE_NOTIFICATION, undefined, undefined, JSON.stringify(request), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }
        // TODO : return the id
        return {};
    }

    async getSubsAPI(request: getSubsRequest) : Promise<getSubsResponse> {
        // call fetchData of Account
        // console.log('Fetching subs..');

        return new Promise((resolve, reject) => {
            this.fetchData(GET_SUBS_API, request).then((res) => {
                
                this.getObjectFromResponse(res).then(obj => {
                    
                    if (!obj || !obj.result) reject(`trying to get subs: obj: ${obj}`);
                    obj = obj.result;

                    const ret: User[] = obj.map(this.obj2user);                    

                    // return response
                    resolve({subs: ret});
                    
                }).catch(reject);
            }).catch(reject);
            
        })
        
    }

    async getFollowersAPI(request: getFollowersRequest) : Promise<getFollowersResponse> {
        // call fetchData of Account
        // console.log('Fetching subs..');

        return new Promise((resolve, reject) => {
            this.fetchData(GET_FOLLOWERS_API, request).then((res) => {
                
                this.getObjectFromResponse(res).then(obj => {
                    
                    if (!obj || !obj.result) reject(`trying to get subs: obj: ${obj}`);
                    obj = obj.result;

                    const ret: User[] = obj.map(this.obj2user);                    

                    // return response
                    resolve({followers: ret});
                    
                }).catch(reject);
            }).catch(reject);
            
        })
        
    }

    async reportAnalytic(data: analyticReportRequest): Promise<void> {
        // call fetchData of Account
        const res = await this.fetchData(REPORT_ANALYTIC, undefined, undefined, JSON.stringify(data), "POST");

        if (res.status !== 200){
            throw Error(JSON.stringify((await this.getObjectFromResponse(res))))
        }    
    }

    private obj2user(obj: any): User {
        return {
            firstName: obj.first_name,
            lastName: obj.second_name,
            email: obj.email,
            id: obj.id.toString(),
            bio: obj.bio,
            topics: obj.topics,
            creationTime: obj.creation_time,
            role: obj.user_type,
            subscryptions: obj.subs,
            followers: obj.followers,
            followedBy: obj.follows_by,
            location: obj.location,
            birthday: obj.birthday,
            phoneNumber: obj.phone_number,
            image: obj.profile_image,
            coverImage: obj.cover_image,
            prefered_language: obj.user_lang,
            notifications: obj.notifications
        }
    }

    private obj2post(obj: any): Post {   
        
        return {
            id: obj.id.toString(),
            parentId: obj.parent_id,
            authorId: obj.writer_id,
            authorName: `${obj.first_name} ${obj.second_name}`,
            title: obj.title,
            contant: obj.content,
            creationTime: new Date(obj.creation_time),
            emotions: obj.emotions,
            authorImage: obj.profile_image,
            comments: !obj.comments? [] : obj.comments.map((comment: any) => {
                if (typeof comment === 'number' || typeof comment === 'string'){
                    return comment.toString();
                }
                
                if ("id" in comment) {
                    return this.obj2post(comment)
                }

                return comment
            })
        };
    }

    private obj2forum(obj: any): Forum {   
        return {
            id: obj.id.toString(),
            parentId: obj.parent_id,
            authorId: obj.writer_id,
            authorName: `${obj.first_name} ${obj.second_name}`,
            contant: obj.content,
            creationTime: new Date(obj.creation_time),
            emotions: obj.emotions,
            authorImage: obj.profile_image,
            comments: !obj.comments? [] : obj.comments.map((comment: any) => {
                if (typeof comment === 'number' || typeof comment === 'string'){
                    return comment.toString();
                }
                
                if ("id" in comment) {
                    return this.obj2forum(comment)
                }

                return comment
            }),
            owner_id: obj.owner_id,
            title: obj.title
        };
    }

    private obj2savedPost(obj: any): SavedPost {   
        return {
            id: obj.id.toString(),
            writerId: obj.writer,
            contant: obj.content,
            creationTime: new Date(obj.creation_time),
            modificationTime: new Date(obj.modification_time)
        };
    }

    private async getObjectFromResponse(response: Response){
        return await response.json()
    }
}

