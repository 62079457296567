import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { User } from "../../data/types";
import { Subs, UserLogged } from "../../navigation/AppRouter";
import { RequireAuth } from "../../utils/auth/RequireAuth";
import { useIsMobile } from "../../utils/general/IsMobile";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import styles from "./HubScreen.module.scss";
import { AccountContext, AccoutContextType } from "../../utils/Account";

type ScreenProps = {
  className?: string;
  sidebarContent?: React.ReactNode;
  footer?: boolean;
  noSidebar?: boolean;
};

export default function HubScreen({ className, sidebarContent, footer, noSidebar }: ScreenProps) {
  const isMobile = useIsMobile();
  const [menuOpen, setMenuOpen] = useState<Boolean>(false);
  const location = useLocation();
  const user = useContext(UserLogged) as User; 
  const { apiHelper } = useContext(AccountContext) as AccoutContextType;

  const mobileClass = isMobile? styles.mobile : undefined;  
  const subs = useContext(Subs) as User[];  

  useEffect(() => {    
    setMenuOpen(false);    
  }, [location])  

  useEffect(() => {        
    apiHelper.reportAnalytic({type: 'PAGE_LOAD', location: location.pathname})
  }, [location.pathname])  

  return (
    <RequireAuth>
      <div className={styles.fullPage}>
        <Header toggleMenu={() => setMenuOpen(!menuOpen)}/>
        <div className={classnames(styles.horizontal, mobileClass)}>
          <div className={classnames(styles.content)} id="window_content">
            {className? <div className={className}><Outlet /></div> : <Outlet />}
            {footer && <div className={styles.pageFooter}></div>}
          </div>
          {/* {sidebarContent &&  */}
          {/* <div className={classnames(styles.sidebarContainer, mobileClass, openMenuClass)}>
            <SideBar authors={subs}/>
          </div> */}
          {(user && user.followers.length > 0 && noSidebar !== true) && <SideBar authors={subs} open={menuOpen}/>}
        </div>
      </div>
    </RequireAuth>
  );
}
