
export enum timeIntervals {
  years = 'years',
  months = 'months',
  weeks = 'weeks',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds'
}

export function timeSince(date: Date, short: boolean = false, from?: Date): [number, string] {
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    return getTimeInterval(seconds, short)
}

export function getTimeInterval(seconds: number, short: boolean = false) {
  var interval = seconds / 31536000;
  
  if (interval > 1) {
    return _getFinal(interval, timeIntervals.years, short);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return _getFinal(interval, timeIntervals.months, short);
  }
  interval = seconds / 604800;
  if (interval > 1) {
      return _getFinal(interval, timeIntervals.weeks, short);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return _getFinal(interval, timeIntervals.days, short);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return _getFinal(interval, timeIntervals.hours, short);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return _getFinal(interval, timeIntervals.minutes, short);
  }
  
  return _getFinal(seconds, timeIntervals.seconds, short);
}

function _getFinal(units: number, interval: timeIntervals, short: boolean = false): [number, string] {
  const i = short? interval[0] : interval;

  return [Math.floor(units), i];
}
