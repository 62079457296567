import styles from "./ForumCard.module.scss";
import { useState} from "react";
import Card from "../basic/Card/Card";
import TextArea from "../basic/TextArea/TextArea";
import Button, { ButtonColor, ButtonVariant } from "../basic/Button/Button";
import { Forum, User } from "../../data/types";
import { useContext } from "react";
import { UserLogged } from "../../navigation/AppRouter";
import Link from "../basic/Link/Link";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import ForumComment from "./ForumComment/ForumComment";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import ContantRender from "../ContentCard/ContantPaging/ContantPaging";

const COMMENTS_LENGTH_SHOW_DEFAULT = 50

export interface ForumCardProps {
  post: Forum;
}

export default function ForumCard({ post }: ForumCardProps) {
  const {t} = useTranslation()

  const [postState, setForumState] = useState<Forum>(post);

  const user = useContext(UserLogged) as User;
  const { apiHelper } = useContext(AccountContext) as AccoutContextType;

  const [commentInput, setCommentInput] = useState("");
  const [maxCommentShow, setMaxCommentShow] = useState<number>(
    Math.min(COMMENTS_LENGTH_SHOW_DEFAULT, post.comments.length)
  );

  const [submitCommentLoad, setSubmitCommentLoad] = useState<boolean>(false);


  const addCommnet = () => {
    const commentText = commentInput;
    if (commentText === '') return; // ensure not empty
    
    const newComment: Forum = {
      authorId: user.id, 
      authorName: `${user.firstName} ${user.lastName}`, 
      authorImage: user.image,
      contant: [{type: "paragraph", children: [{"text": commentText}]}], 
      parentId: postState.id, 
      comments: [],
      creationTime: new Date(),
      emotions: {},
      id: `${postState.id}-${postState.comments.length}`,
      owner_id: postState.owner_id
    } 

    setSubmitCommentLoad(true);
    
    // TODO : add API call to add comment
    apiHelper.addForumPost({
      owner: postState.owner_id,
      parent_id: postState.id, 
      // parent_parent_id: postState.parentId ?? "",
      content: [{"type": "paragraph", "children": [{"text": commentText}]}]
    }).then((res) => {
      newComment.id = res.post_id;
      
      // if API call sceeded
      setForumState({
        ...postState,
        comments: [...postState.comments, newComment]
      }
      )

      setMaxCommentShow(maxCommentShow + 1)
      setCommentInput("");
    }).catch((err) => {
      // TODO : show error
      console.error("did not managed to add comment")
      console.error(err)
    }).finally(() => setSubmitCommentLoad(false));
  }

  const setComments = (comments: (Forum | string)[]) => {
    setForumState({
      ...postState,
      comments: comments
    })
  }

  let time = Math.ceil(postState.contant[0].children[0].text.split(" ").length / 300);
  let timeStatistics = 'Read Time: '+  Math.floor(time/24/60) + " Days, "  + Math.floor(time/60%24) + " Hours, " + Math.floor(time%60)+ ' mins'
  // let rating = ((time/50) * (postState.comments.length / 2) * (postState.likes?postState.likes:0)/2).toFixed(1)  
  
  return (
    <Card className={styles.ContentCard} key={postState.id}>
      <div className={styles.postContainer}>
        <div className={styles.titleElement}>
          <h1>{postState.title}</h1>
          <div>{t('opened_by')}: <Link link={MainNavigationKeys.Profile + `/${postState.authorId}`}>{postState.authorName}</Link></div>
        </div>
      </div>

      <div className={styles.commentsContainer}>
        <ForumComment post={postState} maxVisable={maxCommentShow} 
          setMaxVisable={setMaxCommentShow} setComments={setComments}/>
      </div>
  
      <div className={styles.addCommnet}>
        {t('comment_label')}
        <TextArea className={styles.textArea} value={commentInput} onChange={setCommentInput} maxHeight={100} placeholder={t('comment_placeholder')}/>
        <Button 
          className={styles.rightContiner} 
          variant={ButtonVariant.Contained} 
          color={ButtonColor.Black} 
          onClick= {addCommnet}
          isLoading={submitCommentLoad}
        >
            {t('submit_btn')}
        </Button>     
      </div>
    </Card>
  );
}