import classnames from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useOnResize } from '../../hooks/resize';
import styles from './HorizontalCollection.module.scss';

export interface ElementComponentProps {
    element: any,
    maxWidth: number
}

export interface HorizontalCollectionProps {
    fetchPosts: (limit: number, offset: number) => Promise<any[]>;
    ElementComponent: FC<ElementComponentProps>;
    breakingWidth?: number;
    className?: string;
    setEmpty?: (empty: boolean) => void;
}

export default function HorizontalCollection({
    fetchPosts,
    className,
    breakingWidth = 250,
    ElementComponent,
    setEmpty
}: HorizontalCollectionProps){

    const [maxVisible, setMaxVisible] = useState<number>(6);
    const [start, setStart] = useState<number>(0);
    const [posts, setPosts] = useState<any[]>([]);

    const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const fetchMore = useCallback(async () => {
        return fetchPosts(maxVisible, posts.length).then(newPosts => {
            const updated = [...posts, ...newPosts];
            setPosts(updated)
            return updated.length
        }).then((length) => {
            if (setEmpty) setEmpty(length <= 0)
            return length
        })
    }, [maxVisible, posts]);

    useEffect(() => {
        fetchMore();
    }, []);

    const next = useCallback(() => {
        if(posts.length < start + maxVisible + 1){
            fetchMore().then((length) => setStart(Math.min(start + 1, length - maxVisible)))
        } else {
            setStart(start + 1);
        }
    }, [start, posts, maxVisible])

    const back = useCallback(() => {
        setStart(Math.max(0, start - 1));
    }, [start]);

    const calculateWidth = useCallback(() => {
        const currentWidth = containerRef.current.offsetWidth;

        setMaxVisible(Math.floor(currentWidth / breakingWidth));
    }, []);

    useOnResize(calculateWidth);

    return (<div className={classnames(styles.grid, className)}>
        <div className={styles.navigateBtn} onClick={back}><p>{"<"}</p></div>
        <div className={styles.slide} ref={containerRef}>
            {posts.slice(start, start + maxVisible).map((element) => {
                return (<ElementComponent element={element} maxWidth={breakingWidth - 10}/>)
            })}
        </div>
        <div className={styles.navigateBtn} onClick={next}><p>{">"}</p></div>
    </div>);
}