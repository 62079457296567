import styles from './CompleteUserDetailsScreen.module.scss'
import { useSelector } from 'react-redux';
import { LoginStatus } from '../../../strore/SessionAuth';
import { RootState } from '../../../strore/store';
import logoIcon from '../../../assests/icons/icon.svg'
import ProfileDetailsForm from '../../../components/App/ProfileDetailsForm/ProfileDetailsForm';
import { Navigate } from 'react-router-dom';
import { MainNavigationKeys } from '../../../navigation/app/MainNavigationKeys';


export default function CompleteUserDetailsScreen(){

    const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)

    if (loginStatus !== LoginStatus.NEED_TO_COMPLETE_DETAILS) {
        return <Navigate to={MainNavigationKeys.EditProfile} />
    }    

    return (
        <div className={styles.fullPage}>
            <div className={styles.edge}></div>
            <div className={styles.preview}>
                <div className={styles.iconBtn}><img src={logoIcon}/></div>
                <ProfileDetailsForm/>
            </div>
            <div></div>
            <div className={styles.edge}></div>
        </div>
    );
}
