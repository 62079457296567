import classnames from "classnames";
import { useContext } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Emotion, NotificationType } from "../../data/types";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import AvatarImage from "../AvatarImage/AvatarImage";
import Link from "../basic/Link/Link";
import styles from './NotificationBlock.module.scss';


export interface NotificationBlockProps {
    notification: NotificationType;
    onEnter?: () => void;
}

export default function NotificationBlock({notification, onEnter} :NotificationBlockProps){

    const navigate = useNavigate();
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    let commentContent = <></>;
    let onClickCallback = () => {};

    if (notification.notification_type === 'NEW_POST'){
        commentContent = <>
            <p><Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link> has posted a new post</p>
        </>
        onClickCallback = () => navigate(`${MainNavigationKeys.ViewPost}/${notification.data.post_id}`)
    } else if (notification.notification_type === 'NEW_FORUM'){
        commentContent = <>
            <p>New forum was added to <Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link></p>
        </>
        onClickCallback = () => navigate(`${MainNavigationKeys.Forum}/${notification.data.post_id}`)
    } else if (notification.notification_type === 'NEW_SUGGESTION'){
        commentContent = <>
            <p>New suggestion was added to you by <Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link></p>
        </>
        onClickCallback = () => navigate(`${MainNavigationKeys.Forum}/${notification.data.post_id}`)
    } else if (notification.notification_type === 'NEW_EMOTION') {
        commentContent = <>
            <p><Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link> gave you a {Emotion[+notification.data.emotion]}</p>
        </>
        onClickCallback = () => navigate(`${MainNavigationKeys.ViewPost}/${notification.data.post_id}`)
    } else if (notification.notification_type === 'NEW_COMMENT') {
        commentContent = <>
            <p><Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link> comment on one of yout posts</p>
        </>
        onClickCallback = () => navigate({pathname:`${MainNavigationKeys.ViewPost}/${notification.data.root_post}`, search: `?${createSearchParams({focused: notification.data.post_id})}`})
    } else if (notification.notification_type === 'NEW_FOLLOWER') {
        commentContent = <>
            <p><Link link={`${MainNavigationKeys.Profile}/${notification.data.writer_id}`}>{notification.first_name} {notification.second_name}</Link> started to follow you!</p>
        </>
        onClickCallback = () => navigate(`${MainNavigationKeys.Profile}/${notification.data.writer_id}`)
    }

    const reactAndSetInactive = async () => {
        await apiHelper.deactivateNotification({notifications: [notification.id]})
        .then(onClickCallback)

        onEnter && onEnter();
    }

    return (<div className={classnames(styles.container, notification.active && styles.active)} onClick={reactAndSetInactive}>
        <AvatarImage className={styles.userImage} srcImg={notification.user_image}/>
        <div className={styles.contant}>
            {commentContent}
        </div>
    </div>);
}