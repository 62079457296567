import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import HubScreen from "../../components/HubScreen/HubScreen";
import { UserRole } from "../../data/types";
import { useQueryState } from "../../hooks/useQueryState";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { searchRequest, searchResult } from "../../utils/api/ApiTypes";
import styles from './SearchScreen.module.scss';
import loadingGif from '../../assests/gif/loader.gif';
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import qs from "qs";


export interface SearchScreenProps {

}

export default function SearchScreen({}: SearchScreenProps) {

    const [searchText, setSearchText] =  useState<string>('');
    const forceUpdate = useForceUpdate();
    const location = useLocation()

    const [fetching, setFetching] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<searchResult[]>([])

    const navigate = useNavigate();
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    useEffect(() => {
        const text = qs.parse(location.search, {ignoreQueryPrefix: true,}).search as string;
        setSearchText(text)
        fetchSuggestions(text)
        .then(() => forceUpdate());
    }, [location])

    const fetchSuggestions = useCallback(async (text: string) => {
        if (fetching) return;

        if (text === "") {
            setSuggestions([]);
            return;
        }

        setFetching(true);
        const words = text.split(' ')
        const request: searchRequest = {first_name: words[0]}
        if (words.length > 1){
            request.second_name = words[1]
        }


        return apiHelper.search(request)
        .then(res => setSuggestions(res.results))
        .catch(console.error)
        .finally(() => setFetching(false));

    }, [apiHelper, fetching]);

    const goToProfile = (id: string) => {
        navigate(MainNavigationKeys.Profile + `/${id}`);
    }

    const authors = suggestions.filter(res => res.role === UserRole.AUTHOR);
    const readers = suggestions.filter(res => res.role === UserRole.READER);
        
    return (<div className={styles.searchScreen}>
        <h3>Showing results for "{searchText}"</h3>
        {<div className={styles.suggestionsDropDownContainer}>
            {fetching ? 
            <div className={styles.suggestion}>
                <AvatarImage className={styles.loader} radius={10} srcImg={loadingGif}/>
            </div> : 

            <div className={styles.resultsContainer}>
                
                {authors.length > 0 && <>
                    <p>Authors</p>
                    {authors.map((result, i) => {
                        return (<div className={styles.suggestion} onMouseDown={() => goToProfile(result.id)}>   
                            <AvatarImage radius={15} srcImg={result.profile_image} />
                            {`${result.first_name} ${result.last_name}`}
                        </div>);
                    })}
                </>}

                {readers.length > 0 && <>
                    <p>Readers</p>
                    {readers.map((result, i) => {
                        return (<div className={styles.suggestion} onMouseDown={() => goToProfile(result.id)}>   
                            <AvatarImage radius={15} srcImg={result.profile_image} />
                            {`${result.first_name} ${result.last_name}`}
                        </div>);
                    })}
                </>}

                {suggestions.length === 0 && <p>No results.</p>}
            </div>}
        </div>}
    </div>);
}
