import classnames from "classnames"
import { ReactNode, useRef } from "react"
import Button, { ButtonColor, ButtonVariant } from "../Button/Button"
import styles from './Popup.module.scss'
import { useClickOutside } from "../../../hooks/useClickOutside"


export interface PopupProps {
    children: ReactNode;
    open: boolean;
    setOpen: (value: boolean) => void;
    addCloseButton?: boolean;
}

export default function Popup({
    children,
    open,
    setOpen,
    addCloseButton = false,
}: PopupProps) {

    const popupRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    useClickOutside(popupRef, () => open && setOpen(false));

    return <div className={classnames(styles.fullScreenPopupWrapper, open && styles.open)}>
        <div ref={popupRef} className={classnames(open && styles.open, styles.popupContainer)}>
            {children}
            {addCloseButton && <Button 
                className={styles.popupCloseBtn} 
                variant={ButtonVariant.Contained} 
                color={ButtonColor.Red}
                onClick={() => setOpen(false)}>
                    Close
            </Button>}
        </div>
    </div>
}