import styles from './ProfileDetailsForm.module.scss'
import Button, { ButtonColor, ButtonVariant } from '../../basic/Button/Button';
import { useContext, useEffect, useState } from 'react';
import { AccountContext, AccoutContextType } from '../../../utils/Account';
import { useNavigate } from 'react-router-dom';
import { MainNavigationKeys } from '../../../navigation/app/MainNavigationKeys';
import { useSelector } from 'react-redux';
import { LoginStatus } from '../../../strore/SessionAuth';
import { RootState } from '../../../strore/store';
import moment from 'moment';
import { UserLogged } from '../../../navigation/AppRouter';
import { PostTopic, User } from '../../../data/types';
import Input from '../../basic/Input/Input';
import { addUserRequest, updateUserRequest } from '../../../utils/api/ApiTypes';
import { useTranslation } from 'react-i18next';
import TextArea from '../../basic/TextArea/TextArea';
import Link from '../../basic/Link/Link';


export default function ProfileDetailsForm(){

    const { t } = useTranslation(); 
    
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;
    const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [birthday, setBirthday] = useState<Date>(new Date());
    const [bio, setBio] = useState<string>('');
    const [topics, setTopics] = useState<Set<PostTopic>>(new Set());

    const [error, setError] = useState<string>();

    const user = useContext(UserLogged) as User;

    const [submitLoading, setSubimtLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user !== undefined && user !== null) {            
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setBirthday(user.birthday? new Date(user.birthday) : new Date());
            setCountry(user.location? user.location.split(', ')[0] : '');
            setRegion(user.location? user.location.split(', ')[1] : '');
            setPhone(user.phoneNumber ?? '');
            setBio(user.bio ?? '')
            setTopics(new Set(user.topics) ?? new Set())
        }
    }, [user]);        

    const submit = () => {
        // TODO : validation
        if (loginStatus === LoginStatus.NEED_TO_COMPLETE_DETAILS) {
            const request = getRequest(firstName, lastName, phone, birthday, country, region, bio, Array.from(topics))
            if (!request) {
                setError('You need to fill your details')
                return;
            } else if (typeof request === 'string') {
                setError(request)
                return;
            }

            apiHelper.addUser(request as addUserRequest)
            .then(res => {                
                window.location.href=MainNavigationKeys.Home;
            }).catch(err => setError(err.message));

        } else {
            // update user            
            const request = getRequest(firstName, lastName, phone, birthday, country, region, bio, Array.from(topics), user)

            if (!request) {
                console.error("details are not legal");
                return;
            } else if (typeof request === 'string') {
                setError(request)
                return;
            } else if (Object.keys(request).length === 0) {
                console.error("there are no changes");
                return;
            } 

            console.log("updating user..");
            setSubimtLoading(true);
            apiHelper.updateUser(request)
            .then(res => {
                window.location.href=MainNavigationKeys.Profile + `/${user.id}`;
            }).catch(err => console.error(err))
            .finally(() => setSubimtLoading(false));
        }
    }   

    const completeDetails = loginStatus === LoginStatus.NEED_TO_COMPLETE_DETAILS;

    return (<>
        <div className={styles.fields}>
            {/* <h1>{t('profile_details_label')}</h1> */}
            <label>{t('first_name_label')}:<Input containerClass={styles.inputContainer} value={firstName} onChange={setFirstName} maxLength={20} className={styles.input}/></label>
            <label>{t('last_name_label')}:<Input containerClass={styles.inputContainer} value={lastName} onChange={setLastName} maxLength={20} className={styles.input}/></label>
            <label>Birthday:<div className={styles.inputContainer}><input className={styles.input} value={moment(birthday).format("YYYY-MM-DD")} onChange={(event) => setBirthday(new Date(event.target.value))} type="date"/></div></label>
            <label>Bio:<div className={styles.inputContainer}><TextArea className={styles.input} value={bio} onChange={setBio} maxHeight={200}/></div></label>
        </div>
        <div className={styles.submitButtons}>
            {!completeDetails && <Button variant={ButtonVariant.Contained} color={ButtonColor.Green} onClick={() => navigate(-1)}>
                {t('cencel_btn')}
            </Button>}
            <Button variant={ButtonVariant.Contained} color={ButtonColor.Green} onClick={submit} isLoading={submitLoading}>
                {t('save_btn')}
            </Button>
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
        {!completeDetails && <Link link={MainNavigationKeys.ChangePassword}>Change Password</Link>}
    </>);
}

function getRequest(
    firstName: string, 
    lastName: string, 
    phoneNumber: string, 
    birthday: Date,
    country: string,
    region: string,
    bio: string,
    topics: PostTopic[],
    user?: User) {

        if (!firstName || !lastName) {
            return 'Must set first name and last name.';
        }

        if ((new Date()).getTime() - birthday.getTime() < 18 * 365 * 24 * 60 * 60 * 1000) {
            return 'You must be over 18.'
        }

        const changes: updateUserRequest = {}
        if (!user || firstName !== user.firstName) changes.first_name = firstName;
        if (!user || lastName !== user.lastName) changes.second_name = lastName;
        if (!user || bio !== user.bio) changes.bio = bio;
        if (!user || topics !== user.topics) changes.topics = topics;
        if (!user || (phoneNumber && phoneNumber !== user.phoneNumber)) changes.phone_number = phoneNumber;
        if (!user || birthday !== user.birthday) changes.birthday = birthday.toISOString();
        if (!user || `${country}, ${region}` !== user.location) changes.location = `${country}, ${region}`;
        
        return changes;
}
