import HubScreen from "../../components/HubScreen/HubScreen";
import styles from "./ForumScreen.module.scss";
import { useContext, useEffect, useState } from "react";
import { Forum, Post, User } from "../../data/types";
import ForumCard from "../../components/ForumCard/ForumCard";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { UserLogged } from "../../navigation/AppRouter";
import { useLocation, useParams } from "react-router-dom";

export default function ForumScreen() {

  const { id } = useParams();

  const { apiHelper, updateLogin } = useContext(AccountContext) as AccoutContextType;
  const user = useContext(UserLogged) as User;
  const location = useLocation()

  const [forum, setForum] = useState<Forum[]>([]);
  const [feteching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    if (!id){
      // setError("Got to profile screen without profile name")
    } else if (user){
      setFetching(true);
      
      apiHelper.getForumsByIdsAPI({ids: [id]})
      .then((res) => setForum(res.forums.filter(post => typeof post !== 'string') as Forum[]))
      .finally(() => setFetching(false))
    }
  }, [user, apiHelper, location])
  

  return (
    <div className={styles.ArticlesScreen}>
      <div className={styles.content}>
        {forum.length > 0 && <ForumCard post={forum[0]} key={forum[0].id}/>}
      </div>
    </div>
  );
}
