import classnames from "classnames";
import { useContext, useState } from "react";
import { Emotion, Emotions, User } from "../../data/types";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import AvatarImage from "../AvatarImage/AvatarImage";
import styles from './EmotionContainer.module.scss'
import loadingGif from '../../assests/gif/loader.gif'
import { UserLogged } from "../../navigation/AppRouter";
import { TableWithEmotionsType } from "../../utils/api/ApiTypes";


export interface EmotionContainerProps {
    className?: string,
    emotions: Emotions,
    post_id: string,
    setEmotions: (emotions: Emotions) => void
    type?: TableWithEmotionsType
}

export default function EmotionContainer({
    className,
    emotions,
    post_id,
    setEmotions,
    type
}: EmotionContainerProps){

    const user = useContext(UserLogged) as User;
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;
    const [fetching, setFetching] = useState<boolean>(false);

    const updateEmotion = (value: Emotion, deleteEmotion: boolean = false) => {
        setFetching(true)
        apiHelper.setEmotion({post_id: post_id, emotion: value, delete: deleteEmotion}, type)
        .then(() => {
            let newEmotions = emotions;
            if (deleteEmotion){
                delete newEmotions[value][user.id]
            }
            else {
                if (newEmotions[value * -1] && newEmotions[value * -1][user.id]){
                    delete newEmotions[value * -1][user.id]    
                }

                if (!newEmotions[value]) {
                    newEmotions[value] = {}
                } 
                newEmotions[value][user.id] = new Date().getTime()
            }

            setEmotions(newEmotions);
        }).finally(() => {setFetching(false)})
    }
    const emotionChooser = (
        <>

            <div className={styles.emotionButton} onClick={() =>
                 updateEmotion(Emotion.LIKE, Boolean(emotions[Emotion.LIKE]) && user.id in emotions[Emotion.LIKE])}>
                {" 👍 "}
                {emotions[Emotion.LIKE]? Object.keys(emotions[Emotion.LIKE]).length: 0}
            </div>
            <div className={styles.emotionButton} onClick={() =>
                 updateEmotion(Emotion.DISLIKE, Boolean(emotions[Emotion.DISLIKE]) && user.id in emotions[Emotion.DISLIKE])}>
                {" 👎 "}
                {emotions[Emotion.DISLIKE]? Object.keys(emotions[Emotion.DISLIKE]).length: 0}
            </div>
        </>
    );

    return <div className={classnames(styles.likesBar, className)}>
        {fetching? <AvatarImage radius={10} className={styles.postLoadingGif} srcImg={loadingGif}/> : emotionChooser}
    </div>

}