import classnames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlay } from 'react-icons/fa';
import { Emotions, Post, User } from '../../../data/types';
import { UserLogged } from '../../../navigation/AppRouter';
import { AccountContext, AccoutContextType } from '../../../utils/Account';
import { TableWithEmotionsType } from '../../../utils/api/ApiTypes';
import { useIsMobile } from '../../../utils/general/IsMobile';
import AvatarImage from '../../AvatarImage/AvatarImage';
import Button from '../../basic/Button/Button';
import Card from '../../basic/Card/Card';
import TextArea from '../../basic/TextArea/TextArea';
import { CommentsGroup } from '../../ContentCard/Comments/CommentsGroup/CommentsGroup';
import EmotionContainer from '../../EmotionContainer/EmotionContainer';
import PostContentRender from '../../PostContentRender/PostContentRender';
import styles from './PostViewer.module.scss';


export interface PostViewerProps {
    post: Post,
    setPost: (value: Post) => void;
    withComments?: boolean
}

export default function PostViewer({post, setPost, withComments}: PostViewerProps) {
    const {t} = useTranslation();
    const isMobile = useIsMobile();

    const [commentInput, setCommentInput] = useState("");
    const [commentBtnLoading, setCommentBtnLoading] = useState<boolean>(false);

    const user = useContext(UserLogged) as User;
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    const setComments = (comments: (Post | string)[]) => {
        setPost( {...post, comments: comments})
    }
  
    const addCommnet = () => {
        const commentText = commentInput;
        if (commentText === '') return; // ensure not empty

        setCommentBtnLoading(true);
        // TODO : add API call to add comment
        apiHelper.addComment({
            parent_id: post.id, 
            parent_parent_id: post.parentId ?? "",
            content: [{type: "paragraph", children: [{"text": commentText}]}]
        }).then((res) => {
            // if API call sceeded
            const newComment: Post = {
            authorId: user.id, 
            authorName: `${user.firstName} ${user.lastName}`, 
            authorImage: user.image,
            contant: [{type: "paragraph", children: [{"text": commentText}]}], 
            parentId: post.id, 
            comments: [],
            creationTime: new Date(),
            emotions: {},
            id: res.post_id
            } 
            
            setComments([...post.comments, newComment])

            setCommentInput("");
        }).catch((err) => {
            // TODO : show error
            console.error("did not managed to add comment")
            console.error(err)
        }).finally(() => setCommentBtnLoading(false));
    }

    return (<>
        <div className={classnames(styles.fullScreenContainer, isMobile && styles.mobile)}>
          <Card className={styles.contentCard}>
            <PostContentRender content={post.contant}/>
            <EmotionContainer 
              className={styles.rootPostEmotions}
              emotions={post.emotions} 
              post_id={post.id} 
              setEmotions={(emotions: Emotions) => {setPost({...post, emotions: emotions})}}
              type={TableWithEmotionsType.Posts}
            />
          </Card>
          {withComments && <div className={styles.commentsContainer}>
            <div className={styles.commentsWraper}>
              <CommentsGroup comments={post.comments as Post[]} minVisable={3} setComments={setComments}/>
            </div>
            <div className={styles.addCommnet}>
              <AvatarImage srcImg={user.image}/>
              <TextArea className={styles.textArea} value={commentInput} onChange={setCommentInput} maxHeight={100} placeholder={t('comment_placeholder')}/>
              <div className={styles.commentBtn} onClick={addCommnet}><FaPlay/></div>
            </div>
          </div>}
        </div>
    </>);
}