import classnames from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useOnScreen } from '../../../hooks/useInViewPort';
import { ElementComponentProps } from '../../HorizontalCollection/HorizontalCollection';
import styles from './FeedLoader.module.scss'
import loadingGif from '../../../assests/gif/loader.gif'
import AvatarImage from '../../AvatarImage/AvatarImage';

export interface FeedLoaderProps {
    fetchPosts: (limit: number, offset: number) => Promise<any[]>;
    ElementComponent: FC<ElementComponentProps>;
    breakingWidth: number;
    className?: string;
}

export default function FeedLoader({className, ElementComponent, breakingWidth, fetchPosts}: FeedLoaderProps){

    const fetchAmount = 5;

    const [posts, setPosts] = useState<any[]>([]);
    const [fetchedAll, setFetchedAll] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loaderRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const loaderInView = useOnScreen(loaderRef);

    const fetchMore = useCallback(async () => {
        if (loading){
            return;
        }
        setLoading(true);
        return fetchPosts(fetchAmount, posts.length)
        .then(newPosts => {
            const updated = [...posts, ...newPosts];
            setPosts(updated)
            
            if (newPosts.length < fetchAmount){
                setFetchedAll(true);
            }
        })
        .then(() => setLoading(false));
        
    }, [posts, fetchAmount, fetchPosts, loading])

    useEffect(() => {
        if (loaderInView && !fetchedAll) {
            fetchMore();
        }
    }, [loaderInView, fetchedAll, loading])

    return (<div className={classnames(styles.container, className)}>
        <div className={styles.grid} style={{display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${breakingWidth}px, 1fr))`}}>
            {(fetchedAll && posts.length === 0)? <h2>No posts</h2> : posts.map((element) => {
                return (<ElementComponent element={element} maxWidth={breakingWidth - 10}/>)
            })}
        </div>
        {!fetchedAll && <div className={styles.loader} ref={loaderRef}>
            <AvatarImage radius={20} className={styles.postLoadingGif} srcImg={loadingGif}/>
        </div>}
    </div>);
}