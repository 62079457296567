export enum MainNavigationKeys {
  LandingPage = '/landing-page',
  Welcome = '/welcome',
  Articles = "/articles",
  Home = "/home",
  Profile = "/profile",
  Forum = "/forum",
  NewForum = "/new_forum",
  Post = "/post",
  ViewPost = '/view_post',
  Search = '/search',
  EditProfile = "/edit_profile",

  // Authentication
  Login = "/login",
  VerifyEmail = "/verify_email",
  CompleteDetails = "/user_details",
  Register = "/register",
  ResetPassword = "/reset-password",
  ChangePassword = "/change-password",
  OnboardingWizard = "/welcome",
}
