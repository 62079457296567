import { CognitoUserPool } from "amazon-cognito-identity-js";

// const poolData = {
//     UserPoolId: "us-east-1_q6d0iMZfr",
//     ClientId: "25rp9v4oaf28a97talevp0hkkq"
// }

const poolData = {
    UserPoolId: "eu-central-1_hBC8uBZ1F",
    ClientId: "3qe5l6nq3joo3gn7508aiqtttj"
}

export default new CognitoUserPool(poolData);