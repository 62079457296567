import { Emotion, Forum, Post, User, UserRole, SavedPost, PostTopic, NotificationType } from "../../data/types";
import { PostContent } from "../types";

// Server domain (with dev and prod separation)
let SERVER_DOMAIN: string = process.env.REACT_APP_API_ENDPOINT ?? 'http://127.0.0.1:9111';
export { SERVER_DOMAIN };

// User
export const NEED_TO_COMPLETE_DETAILS = "NEED_TO_COMPLETE_DETAILS";
export type getMyUserRequest = {
}
export type getMyUserResponse = {
    user?: User,
    status?: string
}

export type addUserRequest = {
    first_name: string,
    second_name: string,
    phone_number: string,
    location: string,
    birthday: string
}
export type addUserResponse = {
}

export type updateUserRequest = {
    first_name?: string,
    second_name?: string,
    phone_number?: string,
    location?: string,
    birthday?: string,
    bio?: string,
    topics?: string[],
}
export type updateUserResponse = {
}

export enum ProfileImageType {
    Profile = "PROFILE",
    COVER = "COVER"
}
export type uploadImageRequest = {
    image_type?: ProfileImageType,
    file: any
}
export type uploadImageResponse = {}

export type getUserNotificationsRequest = {
    limit?: number
    active?: boolean;
}

export type getUserNotificationsResponse = NotificationType[]

export type deactivateNotificationRequest = {
    notifications: string[]
}

export type deactivateNotificationResponse = {}

// Profile
export type getProfileRequest = {
    user_id: string
}
export type getProfileResponse = {
    user: User
}

// Suggestions 
export type getSuggestoinsRequest = {owner_id: string, wait_for_execution: boolean}
export type getSuggestionsResponse = {suggestions: any[]}

export type addSuggestionRequerst = {owner_id: string, topic: string}
export type addSuggestionResponse = {suggestion_id: string}

export type updateSuggestionEmotionRequest = {suggestion_id: string, emotion: Emotion}
export type updateSuggestionEmotionResponse = {}

export type deleteSuggestionRequest = {suggestion_id: string}
export type deleteSuggestionResponse = {}

// Post
export type getPostsRequest = {
    writers?: string[],
    offset?: number,
    posts_limit?: number,
    topic?: PostTopic,
}
export type getPostsResponse = {
    posts: (Post | string)[]
}

export type getPostsByIdsRequest = {
    ids: string[],
    get_comments: boolean
}
export type getPostsByIdsResponse = {
    posts: Post[]
}

export type addPostRequest = {
    title?: string;
    topic?: PostTopic;
    content: PostContent,
}

export type deletePostRequest = {
    post_id: string
}
export type deletePostResponse = {}

export type savePostRequest = {
    content: PostContent,
    post_id?: string
}

export type savePostResponse = {
    post_id: string
}

export type getSavedPostsRequest = {
    posts_ids?: string[]
}

export type savedPostsResponse = {
    posts: SavedPost[]
}

export type deleteSavedPostRequest = {
    post_id: string
}

export type deleteSavedPostResponse = {
}

export type addPostResponse = {
}

// Emotions
export enum TableWithEmotionsType {
    Posts,
    Forums
}
export type setEmotionRequest = {
    post_id: string,
    emotion: Emotion,
    delete?: boolean
}
export type setEmotionResponse = {}


// Comments
export type addCommentRequest = {
    content: PostContent
    parent_id: string
    parent_parent_id: string
}
export type addCommentResponse = {
    post_id: string
}

// Subs
export type getSubsRequest = {
}
export type getSubsResponse = {
    subs: User[]
}

// Follow
export type getFollowersRequest = {
}
export type getFollowersResponse = {
    followers: User[]
}
export type addFollowRequest = {
    author_id: string;
}
export type addFollowResponse = {
}

// Forums
export type getForumsRequest = {
    owner_id: string,
    posts_limit?: number,
    offset?: number,
    comments_limit?: number,
}
export type getForumsResponse = {
    forums: (Forum | string)[]
}

export type getForumsByIdsRequest = {
    ids: string[],
    get_comments?: boolean, 
}
export type getForumsByIdsResponse = {
    forums: Forum[]
}

export type addForumRequest = {
    content: PostContent,
    title?: string,
    owner: string,
    parent_id?: string
    // parent_parent_id?: string
}
export type addForumResponse = {
    post_id: string
}

export type deleteForumRequest = {
    post_id: string
}
export type deleteForumResponse = {
}

// Search
export type searchResult = {
    id: string,
    first_name: string,
    last_name: string,
    role: UserRole,
    profile_image: string,
}
export type searchRequest = {
    first_name: string,
    second_name?: string,
    order?: string,
    limit?: number,
    offset?: number,
    reverse?: boolean
}
export type searchResponse = {
    results: searchResult[]
}


// Analytics 
export type analyticReportRequest = {
    type: string,
    [key: string]: any
}
