import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginScreen from "../screens/AuthScreens/LoginScreen";
import PostScreen from "../screens/PostScreen/PostScreen";
import { MainNavigationKeys } from "./app/MainNavigationKeys";

import ChangePasswordScreen from "../screens/AuthScreens/ChangePasswordScreen/ChangePasswordScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../strore/store";
import { ActionTypes, LoginStatus } from "../strore/SessionAuth";
import { createContext, useContext, useEffect, useState } from "react";
import { AccountContext, AccoutContextType } from "../utils/Account";
import { User } from "../data/types";
import FetchingScreen from "../screens/FetchingScreen/FetchingScreen";
import ConfirmEmailScreen from "../screens/AuthScreens/ConfirmEmailScreen/ConfirmEmailScreen";
import CompleteUserDetailsScreen from "../screens/AuthScreens/CompleteUserDetailsScreen/CompleteUserDetailsScreen";
import { NEED_TO_COMPLETE_DETAILS } from "../utils/api/ApiTypes";
import ForumScreen from "../screens/ForumScreen/ForumScreen";
import { useTranslation } from "react-i18next";
import TestingComponents from "../screens/TestingScreen/TestingComponents";
import NewForumScreen from "../screens/NewForumScreen/NewForumScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import ProfileScreen2 from "../screens/ProfileScreen2/ProfileScreen2";
import LandingScreen from "../screens/LandingScreen/LandingScreen";
import ViewPostScreen from "../screens/ViewPostScreen/ViewPostScreen";
import SignUpScreen from "../screens/AuthScreens/SignUpScreen";
import SearchScreen from "../screens/SearchScreen/SearchScreen";
import ConfirmForgotPasswordScreen from "../screens/AuthScreens/ConfirmForgotPasswordScreen/ConfirmForgotPasswordScreen";
import WelcomeScreen from "../screens/WelcomeScreen/WelcomeScreen";
import HubScreen from "../components/HubScreen/HubScreen";
import EditProfileScreen from "../screens/EditProfileScreen/EditProfileScreen";

const UserLogged = createContext<User | null>(null);
const Subs = createContext<User[]>([]);

function AppRouter() {
  const { t } = useTranslation()

  const { updateLogin, apiHelper } = useContext(AccountContext) as AccoutContextType;
  const { loginStatus } = useSelector((state: RootState) => state.sessionReducer);
  const dispatch = useDispatch();

  const [user, setUser] = useState<User | null>(null);  
  const [subs, setSubs] = useState<User[]>([]);

  useEffect(() => {
    setUser(null);
    updateLogin();
  }, [])

  useEffect(() => {
    setUser(null);
    if (loginStatus === LoginStatus.LOGGED_IN){
      apiHelper.getUserAPI({}).then((response) => {
        // console.log(response);
        if (response.status === NEED_TO_COMPLETE_DETAILS){
          dispatch({type: ActionTypes.NEED_TO_COMPLETE_DETAILS});
        } else if (response.user){
          setUser(response.user)
        } 
      });

      // Fetch subs
      apiHelper.getFollowersAPI({})
      .then(res => setSubs(res.followers))
      .catch((err) => {
        console.error(err);
      });
    } 
  }, [loginStatus, apiHelper, dispatch])

  if (loginStatus === LoginStatus.FETCHING){
    return <FetchingScreen text={t('loading_page')}/>
  }

  return (
    <UserLogged.Provider value={user}>
      <Subs.Provider value={subs}>
        <Router>
            <Routes>
            
              <Route path={"/tests"} element={<TestingComponents/>} />
              <Route path={MainNavigationKeys.LandingPage} element={<LandingScreen />} />
              <Route path={MainNavigationKeys.Login} element={<LoginScreen />} />
              <Route path={MainNavigationKeys.Register} element={<SignUpScreen />} />
              <Route path={MainNavigationKeys.VerifyEmail} element={<ConfirmEmailScreen />} />
              <Route path={MainNavigationKeys.ResetPassword} element={<ConfirmForgotPasswordScreen />} />
              <Route path={MainNavigationKeys.CompleteDetails} element={<CompleteUserDetailsScreen />}/>

              <Route element={<HubScreen/>}>
                <Route path={MainNavigationKeys.Welcome} element={<WelcomeScreen />} />
                <Route path={MainNavigationKeys.Articles} element={<HomeScreen />} />
                <Route path={MainNavigationKeys.Profile + "/:id"} element={<ProfileScreen2 />} />
                <Route path={MainNavigationKeys.Forum + "/:id"} element={<ForumScreen />} />
                <Route path={MainNavigationKeys.EditProfile} element={<EditProfileScreen />}/>
                <Route path={MainNavigationKeys.NewForum + "/:id"} element={<NewForumScreen />} />
                <Route path={MainNavigationKeys.Post} element={<PostScreen />} />
                <Route path={MainNavigationKeys.ViewPost + "/:id"} element={<ViewPostScreen />} />
                <Route path={MainNavigationKeys.Search} element={<SearchScreen/>} />
                <Route path={MainNavigationKeys.Home} element={<Navigate to={MainNavigationKeys.Articles} />} />
              </Route>
              
              <Route path={MainNavigationKeys.ChangePassword} element={<ChangePasswordScreen/>} />

              <Route path={"*"} element={<Navigate to={MainNavigationKeys.LandingPage} />} />
            </Routes>
        </Router>
      </Subs.Provider>
    </UserLogged.Provider>
  );
}

export {AppRouter, UserLogged, Subs}
