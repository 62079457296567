import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseSelection, createEditor, Descendant, Editor, Transforms } from "slate";
import { Slate, withReact } from "slate-react";
import styles from "./PostCreationCard.module.scss";
import EditorToolbar from "./Toolbar/Toolbar";
import { CustomEditor } from "../TextEditor/EditorUtils";
import TextEditor from "../TextEditor/TextEditor";
import { withHistory } from "slate-history";
import { PostContent } from "../../utils/types";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { analyticReportRequest } from "../../utils/api/ApiTypes";

export interface PostCreationCardProps {
  // postContentReady: Descendant[];
  document: Descendant[];
  setDocument: (obj: any) => void;
  className?: string;
}

export default function PostCreationCard({
  // postContentReady,
  className,
  document,
  setDocument
}: PostCreationCardProps) {

  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [_, setSelection] = useSelection(editor);
  const { apiHelper } = useContext(AccountContext) as AccoutContextType;

  useEffect(() => {
    // Delete all entries leaving 1 empty node
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })

    // Removes empty node
    Transforms.removeNodes(editor, {
      at: [0],
    })

    // Insert array of children nodes
    Transforms.insertNodes(
      editor,
      document
    )
  }, [])

  const onChangeHandler = useCallback(
    (document: React.SetStateAction<Descendant[]>) => {
      setDocument(document);
      setSelection(editor.selection);
    },
    [editor.selection, setDocument, setSelection]
  );  

  async function report(data: analyticReportRequest) {
    apiHelper.reportAnalytic(data);
  }

  return (
    <Slate editor={editor} value={document} onChange={onChangeHandler}>
      <EditorToolbar className={styles.toolbar} onChangeStyle={report}/>
      <TextEditor
        className={styles.editorClass}
        document={document}
        editor={editor}
        setDocument={setDocument}
        onChangeStyle={report}
      />        
    </Slate>
  );
}

function useSelection(
  editor: CustomEditor
): [BaseSelection, (newSelection: BaseSelection) => void] {
  const [selection, setSelection] = useState(editor.selection);

  const setSelectionOptimized = useCallback(
    (newSelection: BaseSelection) => {
      // don't update the component state if selection hasn't changed.
      if (selection === newSelection) {
        return;
      }
      setSelection(newSelection);
    },
    [setSelection, selection]
  );

  return [selection, setSelectionOptimized];
}
