import classnames from "classnames";
import React from "react";
import styles from "./Card.module.scss";

type CardProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function Card({ children, className }: CardProps) {
  return <div className={classnames([styles.Card, className])}>{children}</div>;
}
