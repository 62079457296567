import { Navigate, useNavigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import styles from './SignUp.module.scss';
import UserPool from "../../utils/UserPool"
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useCallback, useState } from "react";
import logoIcon from '../../assests/icons/icon.svg'
import { LoginStatus } from "../../strore/SessionAuth";
import { useSelector } from "react-redux";
import { RootState } from "../../strore/store";
import loadingGif from '../../assests/gif/loader.gif'
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import Input, { emailValidator, usernameValidator } from "../../components/basic/Input/Input";
import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import Link from "../../components/basic/Link/Link";
import classnames from "classnames";
import Popup from "../../components/basic/Popup/Popup";


export default function SignUpScreen() {

  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<"0" | "1">("1");
  const [termsAccept, setTermsAccept] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { loginStatus } = useSelector((state: RootState) => state.sessionReducer);

  const navigate = useNavigate();

  const signUp = useCallback(async (event: any) => {
    event.preventDefault();

    if (!termsAccept) {
        setError('You need to accept the terms and conditions')
        return;
    }

    setLoading(true);
    setError("");
    UserPool.signUp(username, password, [new CognitoUserAttribute({Name: "email", Value: email}), new CognitoUserAttribute({Name: "custom:role", Value: role})], [], (err, data) => {
        setLoading(false);
        if (err) { 
            if (err.name === "InvalidParameterException"){
                setError('Invalid parameters')
            } else {
                setError(err.message);
            }
            return;
        }

        navigate(MainNavigationKeys.VerifyEmail + `?username=${username}`);
    });
  }, [loading, UserPool.signUp, error, username, password, email, termsAccept]);

  if (loginStatus !== LoginStatus.LOGGED_OUT){
      return <Navigate to={MainNavigationKeys.Home} />
  }

    return (
    <div className={styles.fullPage}>
        <div className={styles.edge}></div>
        <div className={styles.preview}>
            <div className={styles.iconBtn}><img src={logoIcon}/></div>
            <div className={styles.fields}>
                <div className={styles.roleChooser}>
                    <div className={classnames(role === '0' && styles.active)} onClick={() => setRole('0')}>Author</div>
                    <div className={classnames(role === '1' && styles.active)} onClick={() => setRole('1')}>Reader</div>
                </div>
                <label>
                    <b>Email</b>
                    <Input additionalValidators={[emailValidator]} containerClass={styles.inputContainer} value={email} onChange={setEmail} maxLength={50} className={styles.input}/>
                </label>

                <label>
                    <b>Username</b>
                    <Input additionalValidators={[usernameValidator]} containerClass={styles.inputContainer} value={username} onChange={setUsername} maxLength={20} className={styles.input}/>
                </label>

                <label form="psw">
                    <b>Password</b>
                    <Input containerClass={styles.inputContainer} value={password} onChange={setPassword} type={"password"} maxLength={20} className={styles.input}/>
                </label>

                <label>Please read first the: <Link onClick={() => {setTermsOpen(true)}}>terms and conditions</Link></label>

            </div>

            <div className={styles.buttonsVertical}>
                {error && <p className={styles.errorMessage}>{error}</p>}

                <Button isLoading={loading} variant={ButtonVariant.Contained} color={ButtonColor.Green} onClick={signUp}>
                    Create Account!
                </Button>
                
                <Link className={styles.secondaryBtn} onClick={() => navigate(MainNavigationKeys.Login)}>
                    {"Login"}
                </Link>
            </div>
            
        </div>
        <div className={styles.edge}></div>
        <Popup open={termsOpen} setOpen={setTermsOpen}>
            <div className={styles.termsAndConditionsPopup}>
                <p className={styles.termsTextArea}>
                    {termsAndConditinos}
                </p>
                <div>
                    <input type="checkbox" id='terms-and-conditions' checked={termsAccept} onChange={() => setTermsAccept(!termsAccept)}/>
                    <label htmlFor='terms-and-conditions'>I agree to the <b>terms and conditions</b></label>
                </div>
            </div>
        </Popup>
    </div>
    );
}


const termsAndConditinos = `
What to Include in Your Terms and Conditions?
Terms and conditions should always be drafted with the particularities of your business in mind.

The first step when thinking about drafting these terms should always be to identify the risks and liabilities associated with your business or website in order to better address them in your terms.

Here are some clauses which are considered standard in terms and conditions agreements.

General Conditions & Termination
It is your website, therefore you can decide how it should be used.

It is common to see terms and conditions which include a list of prohibitions or general guidelines to be followed by users. These can be broad, such as stating that your services are not to be used for illegal or unlawful purposes or that users must not try to breach or test the vulnerability of your network or circumvent security measures.

If your platform is collaborative, meaning users are invited to share such as on a social media network, you could have a separate page with community guidelines, which you could link to in that clause.

You can reserve the right to terminate the contract or disable user accounts should there be a violation of any of your terms, guidelines, and conditions by including a termination clause – this is very common in the case of websites and SaaS applications.

After all, you want to be able to take action should someone misuse your product. You may even take a step further an create a detailed acceptable use policy to go along with your terms.

Copyright & Intellectual Property
While having an intellectual property clause won’t guarantee that someone won’t copy your work, it can certainly act as a good deterrent.

It also helps having a copyright notice that confirms and warns your users that the content on your website is yours and is not to be reproduced or re-used without your express permission. It re-affirms that you hold trademarks and ownership over certain elements of the website itself and over the products that you sell and should be a no-brainer.

DMCA Takedown Process
It is common practice to include a clause that explains how you process and respond to DMCA takedown notices.

You don’t have to go into detail but you could summarize the important information, include a link to your contact form or email address, and link to your separate DMCA policy.

Products & Services
If you sell products online, you may wish to state that while you do your best to ensure that descriptions are accurate and colors adequately represented. You cannot be held responsible should there be discrepancies in size, shape, or colors with the products received by the customer as their computers may simply have been showing them differently.

The same goes for product availability. You could state that while you aim to ensure that a product for sale is in stock, you won’t be held liable if there was a mistake and a product is sold out, in which case you reserve the right to cancel their order.

Similarly, you should recognize that errors do happen and you could, for example, give yourself the right to correct wrong information, such as prices, and to cancel or refuse to process an order that was made based on that information.

Your business may evolve along the way, therefore you may wish to include a sentence stating that you may add or remove features, products, or services from time to time without any prior notice.

Limitation of Liability & Disclaimers
Limitation of liability disclaimers is one of the main reasons why business owners take the time to include terms and conditions on their websites. When reasonable and drafted adequately, such clauses can help protect your business against claims and lawsuits and limit the amount of money that you would have to pay in damages.
`
