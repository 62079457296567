import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillFolderOpen, AiOutlineSave } from "react-icons/ai";
import { BiRightArrow } from "react-icons/bi";
import { FaKeyboard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Descendant } from "slate";
import Button, { ButtonColor, ButtonVariant } from "../../components/basic/Button/Button";
import Card from "../../components/basic/Card/Card";
import Input from "../../components/basic/Input/Input";
import Link from "../../components/basic/Link/Link";
import HubScreen from "../../components/HubScreen/HubScreen";
import PostCreationCard from "../../components/PostCreationCard/PostCreationCard";
import { PostTopic, SavedPost, User } from "../../data/types";
import { UserLogged } from "../../navigation/AppRouter";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import { timeSince } from "../../utils/general/Time";
import { AlignOptions, PostContent } from "../../utils/types";
import styles from "./PostScreen.module.scss";
import SavedPostsTable from "./SavedPostScreen/SavedPostTable";

const initialValue = [
  {
    type: "paragraph",
    align: "left" as AlignOptions,
    children: [{ text: "" }],
  },
];

export default function PostScreen() {
  const { apiHelper } = useContext(AccountContext) as AccoutContextType;
  const user = useContext(UserLogged) as User;

  const [choosePost, setChoosePost] = useState<boolean>(false);
  const [savedPosts, setSavedPosts] = useState<SavedPost[]>([]);
  const [savedPostsLoding, setSavedPostLoading] = useState<boolean>(false);
  const [currentSavedPost, setCurrentSavedPost] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string>('');
  const [topic, setTopic] = useState<PostTopic>(PostTopic.Economy);

  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [document, setDocument] = useState<Descendant[]>(initialValue);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user){
        setSavedPostLoading(true);

        apiHelper.getSavedPost({})
        .then(res => {
          setSavedPosts(res.posts);
        })
        .catch(console.error)
        .finally(() => setSavedPostLoading(false))
    }
  }, [user, apiHelper]);

  const onSubmit = (content: PostContent) => {
    return apiHelper.addPost({content, topic: topic, title: title}).then(() => navigate(-1));
  }

  const onSave = useCallback(async (content: PostContent) => {
    const res = await apiHelper.savePost({content: content, post_id:currentSavedPost})
    
    if (currentSavedPost !== undefined){
      for (let i = 0; i < savedPosts.length; i ++) {
        if (savedPosts[i].id === currentSavedPost){
          const temp = savedPosts[i];
          temp.contant = content;
          setSavedPosts([...savedPosts.slice(0, i), temp, ...savedPosts.slice(i + 1)]);
          break;
        }
      }
    } else { 
      const newPost: SavedPost = {id: res.post_id, contant: content, creationTime: new Date(), modificationTime: new Date(), writerId: user.id}
      setSavedPosts([...savedPosts, newPost]);
    }
  }, [currentSavedPost, savedPosts, setSavedPosts, user, apiHelper])  

  const submit = () => {
    setPostLoading(true);
    onSubmit(document as PostContent).then(() => {
      console.log("post added");
    }).catch(console.error).finally(() => setPostLoading(false));
  }

  const save = () => {
    if (onSave){
      setSaveLoading(true);
      onSave(document as PostContent).then(() => {
        console.log("post Saved");
        // Add it to saved post side bar
      }).catch().finally(() => setSaveLoading(false));
    }
  }  

  const setSavedPost = (post: SavedPost | undefined) => {
    if (!post) {
      setDocument(initialValue);
      setCurrentSavedPost(undefined);
      setChoosePost(false);
      return;
    }

    setDocument(post.contant);
    setCurrentSavedPost(post.id);
    setChoosePost(false);
  }

  const deleteSavedPost = (id: string, index: number) => {
    console.log(index);
    
    apiHelper.deleteSavedPost({post_id: id})
    .then(() => {
      setSavedPosts([...savedPosts.slice(0, index), ...savedPosts.slice(index + 1)])
    })
  }   

  return (
    <div className={styles.PostScreen}>
      <div className={styles.topPanel}>
        <Input placeholder={t('enter_forum_title_label')} className={styles.titleInput} value={title} 
          onChange={setTitle} maxLength={70}/>
              
        <select value={topic} onChange={(e) => {setTopic(PostTopic[e.target.value as keyof typeof PostTopic])}}>
          {Object.keys(PostTopic).map(key => <option>{key}</option>)}
        </select>
      </div>
      
      <Card className={styles.PostCreationCard}>
        <div className={styles.content}>
          {!choosePost? 
          <PostCreationCard
            // postContentReady={document}
            document={document}
            setDocument={setDocument}
            className={styles.postCard}
          /> : 
          <SavedPostsTable savedPosts={savedPosts} setPost={setSavedPost} deleteSavedPost={deleteSavedPost}/>}
        </div>
        <div className={styles.butonsContainer}>
          <Button 
            title={choosePost? 'back to edit mode': 'choose from saved articles'}
            className={styles.postSubmitButton} 
            variant={ButtonVariant.Contained} 
            color={ButtonColor.Black} onClick={() => setChoosePost(!choosePost)}>
              {!choosePost? <AiFillFolderOpen/> : <FaKeyboard/>}
          </Button>
          <Button 
            title={'save article'}
            className={styles.postSubmitButton} 
            variant={ButtonVariant.Contained} 
            color={ButtonColor.Black} 
            onClick={save} 
            isLoading={saveLoading}>
              <AiOutlineSave/>
          </Button>
          <Button 
            title={'post article'}
            className={styles.postSubmitButton} 
            variant={ButtonVariant.Contained} 
            color={ButtonColor.Black} 
            onClick={submit} 
            isLoading={postLoading}>
              <BiRightArrow/>
          </Button>
        </div>
      </Card>
    </div>
  );
}
