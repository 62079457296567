import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AvatarImage from "../../components/AvatarImage/AvatarImage";
import HubScreen from "../../components/HubScreen/HubScreen";
import { Post } from "../../data/types";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import styles from './ViewPostScreen.module.scss';
import loadingGif from '../../assests/gif/loader.gif'
import PostViewer from "../../components/App/PostViewer/PostViewer";
import { AccountContext, AccoutContextType } from "../../utils/Account";

export interface ViewPostScreenProps {

}

export default function ViewPostScreen(props: ViewPostScreenProps){
    const { id } = useParams();
    const [post, setPost] = useState<Post>();
    const [error, setError] = useState<string>();
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    useEffect(() => {
        if (!id){
            setError("Got to profile screen without profile name");
            return;
        }  
        
        setPost(undefined)
        // setError(undefined)
        apiHelper.getPostsByIdsAPI({ids: [id], get_comments: true})
        .then((posts) => {
            if (!posts.posts || posts.posts.length < 1) {
                setError("No such post")
                return;
            }
                        
            setPost(prev => posts.posts[0])
        })
        .catch(err => {
            console.log('error in fetch: ' + err);
            
            setError(prev => 'error in fetching')
        });
    }, [id]);

    console.log('error: ' + error);

    if (!id) {
        return <ErrorScreen message={"wrong URL."}/>;
    }
    
    if (error) {
        return <ErrorScreen message={error ?? "Unknown Error"}/>;
    }

    if (!post) {
        return (<div className={styles.screen}>
          <AvatarImage className={styles.loader} radius={20} srcImg={loadingGif}/>
        </div>);
    }    

    return (<div className={styles.screen}>
        <PostViewer post={post} setPost={setPost} withComments/>
    </div>);
}