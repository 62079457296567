import classnames from "classnames";
import { ChangeEvent, useState } from "react";
import styles from './Input.module.scss'

export enum ValidationRespose {
    Block,
    Warn
}

export type Validator = {
    error: string,
    valid: boolean,
    response?: ValidationRespose
}

export interface InputProps {
    className?: string;
    containerClass?: string
    value: string;
    onChange: (value: string) => void;
    setError?: (value: string) => void;
    maxLength?: number;
    phone?: boolean;
    additionalValidators?: ((value: string) => Validator)[];
    placeholder?: string;
    type?: string;
}

export default function Input({
    className,
    containerClass,
    value,
    onChange,
    setError,
    maxLength,
    phone,
    additionalValidators,
    placeholder,
    type = 'text'
    
}: InputProps) {

    const [localError, setLocalError] = useState<string>('')
    const setErrorWrapper = (error: string) => {
        if (setError) setError(error);
        setLocalError(error);
    }

    const validators: ((value: string) => Validator)[] = []

    if (phone) validators.push(phoneValidator)
    else if (maxLength) {
        validators.push((val: string) => {
            return {valid: val.length <= maxLength, error: `value is longer then ${maxLength} chars`}
        });
    }

    if (additionalValidators) validators.push(...additionalValidators);

    const onChangeWrapper = (event: ChangeEvent<HTMLInputElement>) => {
        const changeVal = event.target.value;
        let allGood = true;

        for (let i = 0; i < validators.length; i ++) {
            const validation = validators[i](changeVal);
            if (!validation.valid) {
                allGood = false;
                setErrorWrapper(validation.error)
                if (!(validation.response && validation.response === ValidationRespose.Warn)){
                    return;
                }
            }
        }

        if (allGood) setErrorWrapper('');
        onChange(changeVal);
    }

    return (
        <div className={classnames(styles.inputContainer, containerClass)}>
            <input className={classnames(styles.inputInput, className)} placeholder={placeholder} type={type} value={value} onChange={onChangeWrapper}/>
            <div className={styles.inputError}>{localError !== '' && localError}</div>
        </div>
    );
}

function phoneValidator(value: string): Validator {
    // TODO : implement

    return {
        valid: true,
        error: 'phone number is not valid',
        response: ValidationRespose.Warn
    }
}

export function emailValidator(value: string): Validator {
    return {
        valid: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value),
        error: 'not a valid email',
        response: ValidationRespose.Warn
    }
}

export function usernameValidator(value: string): Validator {
    if (!/^[a-z,A-Z,0-9]*$/.test(value)) {
        return {valid: false, error: 'username must be only with letters and numbers', response: ValidationRespose.Warn}
    }

    if (value.length < 8) {
        return {valid: false, error: 'username must be at least 8 characters', response: ValidationRespose.Warn}
    } 

    return {
        valid: true,
        error: '',
    }
}

