import styles from './EditProfileScreen.module.scss'
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LoginStatus } from '../../strore/SessionAuth';
import { RootState } from '../../strore/store';
import { UserLogged } from '../../navigation/AppRouter';
import FetchingScreen from '../FetchingScreen/FetchingScreen';
import { useTranslation } from 'react-i18next';
import ProfileDetailsForm from '../../components/App/ProfileDetailsForm/ProfileDetailsForm';
import { User } from '../../data/types';


export default function EditProfileScreen(){

    const { t } = useTranslation(); 
    const { loginStatus } = useSelector((state: RootState) => state.sessionReducer)
    const user = useContext(UserLogged) as User;

    if (loginStatus !== LoginStatus.NEED_TO_COMPLETE_DETAILS && !user) {
        return <FetchingScreen text={t('loading_page')}/>
    }    

    return (<div className={styles.fullPage}>
        <ProfileDetailsForm/>
    </div>);
}
