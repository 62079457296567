import classnames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useOnScreen } from '../../../hooks/useInViewPort';
import styles from './MobileBottomTabs.module.scss'
import loadingGif from '../../../assests/gif/loader.gif'
import AvatarImage from '../../AvatarImage/AvatarImage';

export interface MobileBottomTabsProps {
    className?: string,
    tabs: {[key: string]: any}
}

export default function MobileBottomTabs({className, tabs}: MobileBottomTabsProps){

    const [currentTab, setCurrentTab] = useState<string>(Object.keys(tabs)[0]);
    const [value, setValue] = useState(1);

    useEffect(() => setCurrentTab(Object.keys(tabs)[0]), [])
    useEffect(() => setValue(old => old * -1), [currentTab])

    return (<div className={classnames(styles.container, className)}>
        <div className={styles.content} key={value}>
            {tabs[currentTab]}
        </div>
        <div className={styles.placeholder}></div>
        <div className={styles.tabsContainer}>
            {Object.keys(tabs).map((tabName => {
                return (<div className={classnames(styles.tabTitle, tabName === currentTab && styles.selected)} onClick={() => setCurrentTab(tabName)}>
                    {tabName}
                </div>);
            }))}
        </div>
    </div>);
}