import { useState, KeyboardEvent, useRef, useContext, useCallback } from "react";
import styles from './SearchBar.module.scss'

import searchIcon from "./../../assests/icons/search.svg"
import classNames from "classnames";

import { searchRequest, searchResult } from "../../utils/api/ApiTypes";
import { AccountContext, AccoutContextType } from "../../utils/Account";
import loadingGif from '../../assests/gif/loader.gif';
import AvatarImage from "../AvatarImage/AvatarImage";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MainNavigationKeys } from "../../navigation/app/MainNavigationKeys";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../utils/general/IsMobile";
import { UserRole } from "../../data/types";

const MAX_SUGGESTIONS = 10;

export interface SearchBarProps {
    className?: string;
}

export default function SearchBar ({
    className
} : SearchBarProps){
    const { t } = useTranslation();
    const isMobile = useIsMobile()

    const [searchText, setSearchText] = useState<string>('');
    const [curretnText, setCurrentText] = useState<string>('');
    const [fetching, setFetching] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<searchResult[]>([])

    const [inputFocus, setInputFocus] = useState<boolean>()
    const [focusedSuggestion, setFocusedSuggestion] = useState<number | undefined>(undefined)

    const inputRef = useRef<HTMLInputElement>(null);
    
    const navigate = useNavigate();
    const { apiHelper } = useContext(AccountContext) as AccoutContextType;

    const fetchSuggestions = useCallback((text: string) => {
        if (fetching) {
            return;
        }

        if (text === "") {
            setSuggestions([]);
            return;
        }

        setFetching(true);
        const words = text.split(' ')
        const request: searchRequest = {first_name: words[0]}
        if (words.length > 1){
            request.second_name = words[1]
        }
        apiHelper.search(request)
        .then(res => setSuggestions(res.results))
        .catch(console.error)
        .finally(() => setFetching(false));

    }, [apiHelper, fetching]);


    const clearInput = () => {
        setSearchText('');
        setCurrentText('');
        if (inputRef.current){
            inputRef.current.blur();
        }
    }

    const moveToSuggestion = (isDown: boolean) => {
        let newVal = focusedSuggestion;
        const delta = isDown? 1 : -1;
        
        const current = focusedSuggestion === undefined? -1 : focusedSuggestion;
        if (current + delta < 0) {
            newVal = undefined;
            setFocusedSuggestion(newVal);
            setCurrentText(searchText);
        } else if (current + delta < suggestions.length) {
            newVal = current + delta;
            setFocusedSuggestion(newVal)
        }

        if (newVal !== undefined) {            
            setCurrentText(`${suggestions[newVal].first_name} ${suggestions[newVal].last_name}`)
        }
    }

    const search = (text: string) => {
        if (focusedSuggestion !== undefined) {
            goToProfile(suggestions[focusedSuggestion].id);
        } else if (text) {
            navigate({pathname: MainNavigationKeys.Search, search: `?${createSearchParams({search: text})}`})
        }

        clearInput();
    }

    const goToProfile = (id: string) => {
        navigate(MainNavigationKeys.Profile + `/${id}`);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
          case 'ArrowUp':
          case 'ArrowDown':
            if (suggestions.length > 0) {
              event.preventDefault();
              moveToSuggestion(event.key === 'ArrowDown');
            }
            break;
    
          case 'Enter':
            search(curretnText);
            break;
    
          case 'Escape':
            clearInput();
            break;
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
    
        setFocusedSuggestion(undefined);
  
        setSearchText(value);
        setCurrentText(value);   
        fetchSuggestions(value);
    }

    const mobileClass = isMobile? styles.mobile : undefined;
    const fucusClass = inputFocus? styles.focus : undefined;

    const authors = suggestions.filter(res => res.role === UserRole.AUTHOR);
    const readers = suggestions.filter(res => res.role === UserRole.READER);

    return (
        <div className={classNames(styles.searchBarContainer, className, mobileClass, fucusClass)}>
            <div className={styles.userInputContainer}>
                <input 
                    ref={inputRef}
                    onFocus={() => setInputFocus(true)} 
                    onBlur={() => setInputFocus(false)} 
                    value={curretnText}  
                    onChange={ handleChange }
                    onKeyDown={suggestions && handleKeyDown}
                    placeholder={t('header.search_placehold')}
                />
                <div className={styles.searchBtn} onClick={() => search(searchText)}>🔎</div>
                {/* <AvatarImage radius={10} className={styles.searchBtn} srcImg={searchIcon} onClick={() => search(curretnText)} /> */}

                {inputFocus && suggestions.length > 0 && <>
                <div className={styles.suggestionsDropDownContainer}>
                    <div className={styles.placeholder}></div>
                {
                    fetching ? 
                    <div className={styles.suggestion}>
                        <AvatarImage className={styles.loader} radius={10} srcImg={loadingGif}/>
                    </div> : 
                    <div className={styles.scrollable}>
                    {authors.length > 0 && <><p>Authors</p>
                    {authors.map((result, i) => {
                        return (
                            <div 
                                className={styles.suggestion}
                                // onMouseOver={() => setFocusedSuggestion(i)}
                                onMouseDown={() => goToProfile(result.id)}
                            >   
                                <AvatarImage radius={15} srcImg={result.profile_image} />
                                {`${result.first_name} ${result.last_name}`}
                            </div>
                        );
                    })}</>}
                    {readers.length > 0 && <><p>Readers</p>
                    {readers.map((result, i) => {
                        return (
                            <div 
                                className={styles.suggestion}
                                // onMouseOver={() => setFocusedSuggestion(i)}
                                onMouseDown={() => goToProfile(result.id)}
                            >   
                                <AvatarImage radius={15} srcImg={result.profile_image} />
                                {`${result.first_name} ${result.last_name}`}
                            </div>
                        );
                    })}</>}
                    </div>
                }
            </div></>}
            </div>
        </div>
    );
}
